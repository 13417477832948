import React from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { navItems } from "../../../static/navItems";
import { useLocation, useNavigate } from "react-router-dom";
import ToggleIcon from "../../Icons/ToggleIcon";
import LightModeIcon from "../../Icons/LightModeIcon";
import DarkModeIcon from "../../Icons/DarkModeIcon";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../../../slices/themeSlice";
import { togglesidebar } from "../../../slices/toggleSlide";
import AddIcon from "../../Icons/AddIcon";
import HelpCenter from "./HelpCenter";
import ProfileSection from "./ProfileSection";

export default function SideBar() {
  const { pathname } = useLocation();
  const isPathMatching = (path) => {
    if (path === "/") return pathname === path;
    return pathname.startsWith(path);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.mode === "dark");
  const isExpanded = useSelector((state) => state.sidebar.mode === "expanded");

  const toggleThemeHandler = () => {
    dispatch(toggleTheme());
  };

  const toggleSideView = () => {
    dispatch(togglesidebar());
  };

  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        alignItems: "start",
        flexGrow: "0",
        flexShrink: "0",
        flexDirection: "column",
        gap: "0",
        overflow: "visible",
        p: "0",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: isExpanded ? "flex-start" : "center",
          justifyContent: "space-between",
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          p: "0",
          overflow: "visible",
          width: isExpanded ? "200px" : "64px",
          color: (theme) => theme.palette.color.secondary,
          backgroundColor: (theme) => theme.palette.color.bg2,
          borderRadius: "16px",
          pb: 1,
          minHeight: "calc(100vh - 155px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: "0",
            width: "100%",
          }}
        >
          <ProfileSection />
          <Box
            mt={3}
            mb={2.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: isExpanded ? "flex-start" : "center",
              p: "8px",
              borderBottom: "1px solid",
              borderColor: (theme) => theme.palette.color.strockborder,
              width: isExpanded ? "100%" : "auto",
            }}
          >
            <Typography variant="caption">Menu</Typography>
            <Box
              mt={2.5}
              sx={{
                display: "flex",
                p: "12px",
                borderRadius: "48px",
                backgroundColor: (theme) => theme.palette.color.skyColor,
                width: isExpanded ? "100%" : "auto",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer"
              }}
              onClick={() => navigate("/new-evaluation")}
            >
              <AddIcon />
              {isExpanded && (
                <Typography variant="subHeading" color={(theme) => theme.palette.color.bg}>New Evolution</Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1",
              backgroundColor: (theme) => theme.palette.color.bg2,
              width: isExpanded ? "100%" : "auto",
              p: "8px"
            }}
          >
            {navItems.map((item) => {
              const isActive = isPathMatching(item.path);
              console.log(isActive);
              return (
                <Tooltip
                  key={item.title}
                  title={item.tooltip}
                  placement="right"
                >
                  <Box
                    onClick={() => navigate(item.to || item.path)}
                    sx={{
                      display: "flex",
                      color: (theme) => theme.palette.color.secondary,
                      p: "12px",
                      "& svg path": {
                        stroke: (theme) => theme.palette.color.secondary,
                      },
                      ...(isActive && {
                        backgroundColor: (theme) =>
                          theme.palette.color.lightgray,
                        color: (theme) => theme.palette.color.primary,
                        borderRadius: "30px",
                        "& svg path": {
                          stroke: (theme) => theme.palette.color.darkText,
                        },
                      }),
                      cursor: "pointer",
                      width: "100%",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <item.icon size={18} />
                    {isExpanded && (
                      <Typography variant="subHeading" color={(theme) => isActive ? theme.palette.color.darkText : "inherit"}>{item.title}</Typography>
                    )}
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "3",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: isExpanded ? "flex-start" : "center",
              flexGrow: "0",
              flexShrink: "0",
              overflow: "visible",
              position: "relative",
            }}
          >
            <Box sx={{ p: "12px", pb: "4px", cursor: "pointer" }} onClick={() => toggleSideView()}>
              <ToggleIcon />
            </Box>
            <Stack direction="row" alignItems="center" gap="5px" sx={{
              cursor: "pointer"
            }}>
              <HelpCenter />
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          alignSelf: "stretch",
          flexGrow: "0",
          flexShrink: "0",
          p: "8px",
          overflow: "visible",
          width: isExpanded ? "200px" : "64px",
          color: (theme) => theme.palette.color.secondary,
          backgroundColor: (theme) => theme.palette.color.bg2,
          borderRadius: "16px",
          mt: 2,
        }}
      >
        <Box
          onClick={() => toggleThemeHandler()}
          sx={{
            display: "flex",
            color: (theme) => theme.palette.color.secondary,
            p: "12px",
            "& svg path": {
              stroke: (theme) => theme.palette.color.secondary,
            },
            borderRadius: "14px",
            ...(!isDarkMode && {
              backgroundColor: (theme) => theme.palette.color.purple,
              color: (theme) => theme.palette.color.primary,
              "& svg path": {
                stroke: (theme) => theme.palette.color.darkText,
              },
            }),
            cursor: "pointer",
            width: isExpanded ? "100%" : "auto",
            justifyContent: "center"
          }}
        >
          <LightModeIcon />
        </Box>
        <Box
          onClick={() => toggleThemeHandler()}
          sx={{
            display: "flex",
            color: (theme) => theme.palette.color.secondary,
            p: "12px",
            "& svg path": {
              stroke: (theme) => theme.palette.color.secondary,
            },
            borderRadius: "14px",
            ...(isDarkMode && {
              backgroundColor: (theme) => theme.palette.color.purple,
              color: (theme) => theme.palette.color.primary,
              "& svg path": {
                stroke: (theme) => theme.palette.color.darkText,
              },
            }),
            cursor: "pointer",
            width: isExpanded ? "100%" : "auto",
            justifyContent: "center"
          }}
        >
          <DarkModeIcon />
        </Box>
      </Box>
    </Box>
  );
}
