import React from "react";

export default function FranceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_4662_659)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M28 14C28 7.98049 24.2007 2.84889 18.8695 0.870789V27.1293C24.2007 25.1511 28 20.0195 28 14Z"
          fill="#D80027"
        />
        <path
          d="M0 14C0 20.0195 3.7993 25.1511 9.13046 27.1292V0.870789C3.7993 2.84889 0 7.98049 0 14Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_4662_659">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
