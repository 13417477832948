import React from "react";

export default function PencilIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.5625 14.4375L6.75 13.6875L13.7197 6.71783C14.0125 6.42493 14.0125 5.95006 13.7197 5.65716L12.3428 4.28033C12.0499 3.98743 11.575 3.98743 11.2822 4.28033L4.3125 11.25L3.5625 14.4375Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
