import React, { useEffect, useRef, useState } from "react";
import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import PrevIcon from "../../Icons/PrevIcon";
import NextIcon from "../../Icons/NextIcon";

const today = dayjs();

const events = [
  {
    id: '1',
    calendarId: '1',
    title: 'Meeting',
    category: 'time',
    start: '2024-07-25T10:30:00+09:00',
    end: '2024-07-25T12:30:00+09:00',
  },
  {
    id: '2',
    calendarId: '1',
    title: 'Lunch',
    category: 'time',
    start: '2024-07-25T12:30:00+09:00',
    end: '2024-07-25T13:30:00+09:00',
  },
  // more events
];

export default function CustomCalendar() {
  const [selectedMonth, setSelectedMonth] = useState(today.startOf("month"));
  const calendarRef = useRef(null);

  const calendarInstance = calendarRef.current?.getInstance();

  const handleNext = () => {
    const month = selectedMonth.add(1, "month").startOf("month");
    setSelectedMonth(month);
  };

  const handlePrev = () => {
    const month = selectedMonth.subtract(1, "month").startOf("month");
    setSelectedMonth(month);
  };

  useEffect(() => {
    if (calendarInstance && Object.keys(calendarInstance).length > 0) {
      calendarInstance.setDate(dayjs(selectedMonth.toDate()));
    }
  }, [selectedMonth, calendarInstance]);

  console.log(selectedMonth);

  const template = {
    time(schedule) {
      return `<div class="schedule-list-month" title="Click to see details"><p>${schedule.title}</p></div>`;
    },
  };

  const onEventClick = (e) => {
   
  };

  return (
    <Box className="custom_calendar-view">
      <Stack direction={"row"} alignItems={"center"} gap={1} sx={{
        mb: 2
      }}>
        <Typography variant="heading_500" color={(theme) => theme.palette.color.darkText}>{selectedMonth.format("MMMM YYYY")}</Typography>
        <IconButton
          onClick={handlePrev}
          sx={{
            p: 0,
          }}
        >
          <PrevIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{
            p: 0,
          }}
        >
          <NextIcon />
        </IconButton>
      </Stack>
      <Calendar
        usageStatistics={false}
        month={{ startDayOfWeek: 1, workweek: true, }}
        events={events}
        useDetailPopup={false}
        useFormPopup={false}
        view={"month"}
        ref={calendarRef}
        template={template}
        onClickEvent={onEventClick}
        gridSelection={{ enableDblClick: false, enableClick: false }}
      />
    </Box>
  );
}
