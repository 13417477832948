import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../components/ui/CustomCard";
import {
  bestPerformingCountries,
  dummyWithDraws,
} from "../static/dashboardItem";
import { Wrapper } from "../components/Home/ActiveAccounts";
import Slider from "react-slick";
import GermanIcon from "../components/Icons/GermanIcon";
import RightArrowIcon from "../components/Icons/RightArrowIcon";
import { useNavigate } from "react-router-dom";

export default function Leaderboard() {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    centerMode: true,
    initialSlide: 1,
  };
  const navigate = useNavigate();

  return (
    <Box mx={2} mt={5} mb={3} minHeight={"calc(100vh - 200px)"}>
      <CustomCard customPadding="24px 16px">
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1} mb={2.5}>
            <Typography
              variant="heading"
              color={(theme) => theme.palette.color.darkText}
            >
              Best performing country
            </Typography>
            <Typography variant="subHeading">In past 30 days</Typography>
          </Box>
          <Button
            className="customButton see_all_btn"
            endIcon={<RightArrowIcon />}
          >
            See all
          </Button>
        </Stack>
        <Grid container spacing={2}>
          {bestPerformingCountries.map((country, index) => {
            return (
              <Grid item xs={2} key={index}>
                <CustomCard
                  className="leaderboard_country_list"
                  customPadding="16px"
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    {country.icon}
                    <Typography
                      variant="h5"
                      color={(theme) => theme.palette.color.darkText}
                    >{`#${index + 1}`}</Typography>
                    <Typography
                      variant="subHeaddingMedium"
                      color={(theme) => theme.palette.color.secondary}
                      fontWeight={"400"}
                    >
                      {country.name}
                    </Typography>
                  </Stack>
                </CustomCard>
              </Grid>
            );
          })}
        </Grid>
      </CustomCard>
      <Box mt={4}>
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1} mb={2.5}>
            <Typography
              variant="heading"
              color={(theme) => theme.palette.color.darkText}
            >
              Highest withdraws
            </Typography>
            <Typography variant="subHeading">In past 30 days</Typography>
          </Box>
          <Button
            className="customButton see_all_btn"
            endIcon={<RightArrowIcon />}
            onClick={() => navigate("/leaderboard/highest-withdraws")}
          >
            See all
          </Button>
        </Stack>
        <Wrapper>
          <Slider {...settings}>
            {dummyWithDraws.map((withdraw, index) => {
              return (
                <CustomCard key={index}>
                  <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                    <Typography
                      variant="h5"
                      color={(theme) => theme.palette.color.darkText}
                    >{`#${index + 1}`}</Typography>
                    <Box
                      sx={{
                        width: "6px",
                        height: "6px",
                        borderRadius: "50%",
                        backgroundColor: (theme) =>
                          theme.palette.color.secondary,
                      }}
                    />
                    <Typography
                      variant="subHeaddingMedium"
                      color={(theme) => theme.palette.color.secondary}
                      fontWeight={"400"}
                    >
                      {withdraw.name}
                    </Typography>
                  </Stack>
                  <Stack
                    gap={1}
                    sx={{
                      marginTop: "8px",
                    }}
                  >
                    <Typography
                      variant="heading2"
                      color={(theme) => theme.palette.color.skyColor}
                    >
                      $110.501.15
                    </Typography>
                    <Typography
                      variant="heading_500"
                      color={(theme) => theme.palette.color.greenShade}
                    >
                      Profit
                    </Typography>
                  </Stack>
                  <Box display={"flex"} flexDirection={"column"} gap={2} mt={3}>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="subHeading">Account</Typography>
                      <Typography
                        variant="heading_500"
                        color={(theme) => theme.palette.color.darkText}
                      >
                        Elite
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="subHeading">Size</Typography>
                      <Typography
                        variant="heading_500"
                        color={(theme) => theme.palette.color.darkText}
                      >
                        $100.000,00
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="subHeading">Country</Typography>
                      <Typography variant="heading_500">
                        <GermanIcon />
                      </Typography>
                    </Stack>
                  </Box>
                </CustomCard>
              );
            })}
          </Slider>
        </Wrapper>
      </Box>
      <Box mt={4}>
        <Stack
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          direction={"row"}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1} mb={2.5}>
            <Typography
              variant="heading"
              color={(theme) => theme.palette.color.darkText}
            >
              Biggest profit per trade
            </Typography>
            <Typography variant="subHeading">In past 30 days</Typography>
          </Box>
          <Button
            className="customButton see_all_btn"
            endIcon={<RightArrowIcon />}
            onClick={() => navigate("biggest-profit")}
          >
            See all
          </Button>
        </Stack>
        <Wrapper>
          <Slider {...settings}>
            {Array(5)
              .fill(null)
              .map((_, index) => {
                return (
                  <CustomCard key={index}>
                    <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                      <Typography
                        variant="h5"
                        color={(theme) => theme.palette.color.darkText}
                      >{`#${1}`}</Typography>
                      <Box
                        sx={{
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          backgroundColor: (theme) =>
                            theme.palette.color.secondary,
                        }}
                      />
                      <Typography
                        variant="subHeaddingMedium"
                        color={(theme) => theme.palette.color.secondary}
                        fontWeight={"400"}
                      >
                        Rick W.
                      </Typography>
                    </Stack>
                    <Stack
                      gap={1}
                      sx={{
                        marginTop: "8px",
                      }}
                    >
                      <Typography
                        variant="heading2"
                        color={(theme) => theme.palette.color.skyColor}
                      >
                        $110.501.15
                      </Typography>
                      <Typography
                        variant="heading_500"
                        color={(theme) => theme.palette.color.greenShade}
                      >
                        Profit
                      </Typography>
                    </Stack>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={2}
                      mt={3}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant="subHeading">Account</Typography>
                        <Typography
                          variant="heading_500"
                          color={(theme) => theme.palette.color.darkText}
                        >
                          Elite
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant="subHeading">Size</Typography>
                        <Typography
                          variant="heading_500"
                          color={(theme) => theme.palette.color.darkText}
                        >
                          $100.000,00
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant="subHeading">Country</Typography>
                        <Typography variant="heading_500">
                          <GermanIcon />
                        </Typography>
                      </Stack>
                    </Box>
                  </CustomCard>
                );
              })}
          </Slider>
        </Wrapper>
      </Box>
    </Box>
  );
}
