import React from "react";

export default function KeyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M13.75 12.1459C14.5561 12.1456 15.3422 11.8954 16.0001 11.4296C16.658 10.9638 17.1551 10.3054 17.4231 9.54516C17.6911 8.78494 17.7167 7.96031 17.4963 7.18495C17.2759 6.40959 16.8205 5.72167 16.1927 5.21603C15.565 4.71038 14.7958 4.41191 13.9913 4.36174C13.1868 4.31158 12.3866 4.51219 11.7009 4.93594C11.0152 5.35968 10.4778 5.98569 10.1628 6.72767C9.84781 7.46965 9.77073 8.29107 9.94219 9.07869L4.35419 14.6667V17.6459H7.33335L8.02085 16.9584V15.3542H9.62502L10.7709 14.2084V12.6042H12.375L12.9214 12.0579C13.1881 12.1156 13.4659 12.1459 13.75 12.1459Z"
        stroke="white"
        stroke-width="1.375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.125 7.33333C15.125 7.45489 15.0767 7.57147 14.9907 7.65742C14.9048 7.74338 14.7882 7.79167 14.6666 7.79167C14.5451 7.79167 14.4285 7.74338 14.3426 7.65742C14.2566 7.57147 14.2083 7.45489 14.2083 7.33333C14.2083 7.21178 14.2566 7.0952 14.3426 7.00924C14.4285 6.92329 14.5451 6.875 14.6666 6.875C14.7882 6.875 14.9048 6.92329 14.9907 7.00924C15.0767 7.0952 15.125 7.21178 15.125 7.33333Z"
        stroke="white"
        stroke-width="0.916667"
      />
    </svg>
  );
}
