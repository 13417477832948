import React from "react";

export default function CylinderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.25 12C19.25 13.105 15.866 14.25 12 14.25C8.134 14.25 4.75 13.105 4.75 12M19.25 7C19.25 8.105 15.866 9.25 12 9.25C8.134 9.25 4.75 8.105 4.75 7C4.75 5.895 8.134 4.75 12 4.75C15.866 4.75 19.25 5.895 19.25 7Z"
        stroke="#8A96A6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.25 7V17C19.25 18.105 15.866 19.25 12 19.25C8.134 19.25 4.75 18.105 4.75 17V7"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
