import { Box, Fab, Popover, Stack } from "@mui/material";
import React, { useState } from "react";
import Support from "../../assets/support.svg";
import PopoverHomeIcon from "../Icons/PopoverHomeIcon";
import PopoverChatIcon from "../Icons/PopoverChatIcon";
import PopoverHome from "../HelpCenter/PopoverHome";
import PopoverHelpIcon from "../Icons/PopoverHelpIcon";
import PopoverChat from "../HelpCenter/PopoverChat";
import PopoverHelp from "../HelpCenter/PopoverHelpCenter";

function ChatSupport() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabval, setTabval] = useState("tabHome");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverTab = (val) => {
    setTabval(val);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          display: "flex",
          alignItems: "center",
          // background: "linear-gradient(to bottom, #0D0D0D 0%, #131517 100%)",
          background: "linear-gradient(135deg, #131517 30%, #754FFF 100%)",
          borderRadius: "50%",
          padding: "3px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          transition: "all 200ms ease-in-out",
          "&:hover": {
            // background: "linear-gradient(to bottom, #131517 100%, #754FFF 20%)",
            transition: "all 200ms ease-in-out",
          },
        }}
      >
        <Fab
          sx={{
            background: "transparent",
            boxShadow: "none",
            color: "#FFFFFF",
            "&::after": {
              content: '""',
              position: "absolute",
              top: -2,
              left: -2,
              right: -2,
              bottom: -2,
              borderRadius: "50%",
              background:
                "linear-gradient(to bottom, #131517 100%, #754FFF 20%)",
              transition: "all 200ms ease-in-out",
              zIndex: -1,
            },
            "&:hover::after": {
              // background: "linear-gradient(to bottom, #131517 100%, #754FFF 20%)",
              transition: "all 200ms ease-in-out",
            },
            border: "1px solid #131517",
          }}
          color="primary"
          aria-label="chat-support"
          onClick={handleClick}
        >
          <img src={Support} alt="Support" />
        </Fab>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '340px',
            borderRadius: '8px',
            overflow: 'hidden',
          }
        }}
      >
        {tabval === "tabHome" && <PopoverHome tabval={tabval} />}
        {tabval === "tabChat" && <PopoverChat tabval={tabval} />}
        {tabval === "tabHelp" && <PopoverHelp tabval={tabval} />}
        <Stack direction={"row"} sx={{ py: 2, px: "40px" }}justifyContent={"space-between"} backgroundColor={ (theme) => theme.palette.color.lightShade} >
          <Box
            onClick={() => handlePopoverTab("tabHome")}
            sx={{ cursor: "pointer" }}
          >
            <PopoverHomeIcon tabval={tabval} />
          </Box>
          <Box
            onClick={() => handlePopoverTab("tabChat")}
            sx={{ cursor: "pointer" }}
          >
            <PopoverChatIcon tabval={tabval} />
          </Box>
          <Box
            onClick={() => handlePopoverTab("tabHelp")}
            sx={{ cursor: "pointer" }}
          >
            <PopoverHelpIcon tabval={tabval} />
          </Box>
        </Stack>
      </Popover>
    </>
  );
}

export default ChatSupport;
