import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AuthLayout from "../components/Login/AuthLayout";

export default function ReserSuccess() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Password reset
          </Typography>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
              paddingTop: "24px",
              paddingBottom: "48px",
              fontSize: "16px",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.color.secondary,
                lineHeight: "22px",
              }}
            >
              Your password has been successfully reset. Click below to log in
              magically.
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, maxWidth: "590px", width: "100%" }}
            >
              <button type="submit" fullWidth className="customAuthButton">
                Continue
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
