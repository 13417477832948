import React from "react";

export default function QuestionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.32016 7.50065C8.47523 7.18766 8.71472 6.92426 9.01159 6.74019C9.30845 6.55613 9.65086 6.45872 10.0002 6.45898C10.3722 6.45897 10.7359 6.56964 11.0448 6.77692C11.3538 6.98419 11.5941 7.27871 11.7352 7.62298C11.8763 7.96725 11.9118 8.34571 11.8371 8.7102C11.7625 9.07469 11.5811 9.40873 11.316 9.66982C10.8993 10.0807 10.3927 10.524 10.1477 11.0448M10.0002 13.5423V13.5507M10.0002 16.0423C9.20676 16.0423 8.42113 15.886 7.68812 15.5824C6.95511 15.2788 6.28908 14.8338 5.72806 14.2728C5.16704 13.7117 4.72201 13.0457 4.41839 12.3127C4.11477 11.5797 3.9585 10.7941 3.9585 10.0007C3.9585 9.20725 4.11477 8.42161 4.41839 7.68861C4.72201 6.9556 5.16704 6.28957 5.72806 5.72855C6.28908 5.16753 6.95511 4.7225 7.68812 4.41888C8.42113 4.11526 9.20676 3.95898 10.0002 3.95898C11.6025 3.95898 13.1392 4.59552 14.2723 5.72855C15.4053 6.86158 16.0418 8.3983 16.0418 10.0007C16.0418 11.603 15.4053 13.1397 14.2723 14.2728C13.1392 15.4058 11.6025 16.0423 10.0002 16.0423Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
