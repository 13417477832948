import {
  Box,
  Grid,
  Stack,
  Typography,
  Checkbox,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import AuthLayout from "../components/Login/AuthLayout";
import FormInput from "../components/ui/FormInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import GoogleIcon from "../components/Icons/GoogleIcon";
import AppleIcon from "../components/Icons/AppleIcon";
import EmailIcon from "../components/Icons/EmailIcon";
import PassHide from "../components/ui/PassHide";
import PassShow from "../components/ui/PassShow";

export default function Register() {
  const [showPassword, setShowPassword] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const handlePassVisiblety = () => {
    setShowPassword((prev) => (prev === "password" ? "text" : "password"));
  };
  const handleConfirmPassVisiblety = () => {
    setShowConfirmPassword((prev) =>
      prev === "password" ? "text" : "password"
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      password: data.get("password"),
      confirmPassword: data.get("confirmPassword"),
    });
  };

  const privacyPolicy = (
    <Box sx={{ color: (theme) => theme.palette.color.secondary }}>
      I agree to the processing of personal data according to privacy policy.
    </Box>
  );

  const serives = (
    <Box sx={{ color: (theme) => theme.palette.color.secondary }}>
      I acknowledge that I am not from/in a country where Wefund doesn't provide
      services.
    </Box>
  );

  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Sign-up for Wefund
          </Typography>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ paddingTop: "24px", justifyContent: "center" }}
          >
            <button className="loginButton">
              <GoogleIcon /> Sign up with Google
            </button>
            <button className="loginButton">
              <AppleIcon /> Sign up with apple
            </button>
          </Stack>
          <div className="no-span section-divider">OR continue with</div>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormInput
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  sx={{
                    background: (theme) => theme.palette.color.lightShade,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.strockborder,
                    borderRadius: "16px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInput
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  sx={{
                    background: (theme) => theme.palette.color.lightShade,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.strockborder,
                    borderRadius: "16px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ position: "relative" }}>
                  <FormInput
                    label="Email"
                    name="email"
                    type="email"
                    required
                    fullWidth
                    placeholder="Enter your email"
                    sx={{
                      background: (theme) => theme.palette.color.lightShade,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.color.strockborder,
                      borderRadius: "16px",
                      pl: "32px",
                      fontSize: "14px",
                      color: "#FFFFFF",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "12px",
                      left: "16px",
                    }}
                  >
                    <EmailIcon />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInput
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  sx={{
                    background: (theme) => theme.palette.color.lightShade,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.strockborder,
                    borderRadius: "16px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ position: "relative" }}>
                  <FormInput
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword}
                    id={"password"}
                    autoComplete="new-password"
                    sx={{
                      background: (theme) => theme.palette.color.lightShade,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.color.strockborder,
                      borderRadius: "16px",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "12px",
                      right: "16px",
                      cursor: "pointer",
                    }}
                    onClick={handlePassVisiblety}
                  >
                    {showPassword === "password" ? <PassHide /> : <PassShow />}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ position: "relative" }}>
                  <FormInput
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword}
                    id="confirmPassword"
                    autoComplete="new-password"
                    sx={{
                      background: (theme) => theme.palette.color.lightShade,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.color.strockborder,
                      borderRadius: "16px",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "12px",
                      right: "16px",
                      cursor: "pointer",
                    }}
                    onClick={handleConfirmPassVisiblety}
                  >
                    {showConfirmPassword === "password" ? (
                      <PassHide />
                    ) : (
                      <PassShow />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sx={{ pl: 2, m: 0, pt: "16px" }}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label={privacyPolicy}
                />
              </Grid>
              <Grid xs={12} sx={{ pl: 2, m: 0 }}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label={serives}
                />
              </Grid>
            </Grid>
            <button type="submit" className="customAuthButton" style={{ marginTop: "24px" }}>
              Sign Up
            </button>
            <Grid container justifyContent="center" sx={{ pt: "48px" }}>
              <Grid item>
                <Link
                  href="/login"
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.color.secondary,
                    textDecoration: "none",
                  }}
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
