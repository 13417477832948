import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AuthLayout from "../components/Login/AuthLayout";
import Link from "@mui/material/Link";
import LeftArrow from "../components/Icons/LeftArrow";

export default function CheckEmail() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Check your email
          </Typography>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
              paddingTop: "24px",
              paddingBottom: "48px",
              fontSize: "16px",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.color.secondary,
              }}
            >
              We sent a password reset link to{" "}
              <Typography
                variant="paragraph"
                sx={{ color: (theme) => theme.palette.color.primary }}
              >
                jens@vuetra.com
              </Typography>
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ maxWidth: "590px", width: "100%" }}
            >
              <button type="submit" fullWidth className="customAuthButton">
                Open Email
              </button>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  paddingTop: "48px",
                  justifyContent: "center",
                  fontSize: "14px",
                }}
              >
                <Typography
                  sx={{ color: (theme) => theme.palette.color.secondary, fontSize: "14px", }}
                >
                  Didn’t receive the email?
                </Typography>
                <span sx={{ color: (theme) => theme.palette.color.skyColor }}>
                  Resend
                </span>
              </Box>
              <Link
                href="/login"
                variant="body2"
                sx={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    paddingTop: "48px",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ color: (theme) => theme.palette.color.secondary,
                      fontSize: "14px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px", }}
                  >
                    <LeftArrow />  Back to login
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
