import React from "react";
import BillingDetails from "./BillingDetails";
import PaymentDetails from "./paymentDetails";
import { Box, Grid, Stack, Typography } from "@mui/material";
import OrderSummary from "../components/Evaluation/OrderSummary";

export default function PaymentInfo() {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "calc(100vh - 32px)",
        backgroundColor: (theme) => theme.palette.color.bg,
        padding: "12px",
      }}
    >
      <Box
        sx={{
          padding: "7px 12px",
          color: (theme) => theme.palette.color.primary,
        }}
      >
        <Stack
          sx={{
            flexDirection: { xs: "column", lg: "row" },
            alignItems: { xs: "flex-start", lg: "center" },
            gap: { xs: 0.5, lg: 2 },
          }}
        >
          <Typography variant="heading2">Payment</Typography>
        </Stack>

        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            display={"flex"}
            sx={{
              background: (theme) => theme.palette.color.bgGrey,
              padding: "8px",
              borderRadius: "30px",
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={{
                marginRight: "12px",
                paddingRight: "12px",
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  width: "1px",
                  height: "24px",
                  background: (theme) => theme.palette.color.strockborder,
                  right: 0,
                },
              }}
            >
              <Box
                sx={{
                  background: (theme) =>
                    currentStep === 1
                      ? theme.palette.color.purple
                      : theme.palette.color.lightShade,
                  borderRadius: "30px",
                  padding: "12px",
                  "svg path": {
                    stroke: (theme) =>
                      currentStep === 1
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,
                  },
                }}
              >
                <BillingIcon />
              </Box>
              {currentStep === 1 && (
                <Stack>
                  <Typography
                    variant="subtitle2_500"
                    color={(theme) => theme.palette.color.purple}
                  >
                    Step 1/3
                  </Typography>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    Billing Details
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={{
                marginRight: "12px",
                paddingRight: "12px",
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  width: "1px",
                  height: "24px",
                  background: (theme) => theme.palette.color.strockborder,
                  right: 0,
                },
              }}
            >
              <Box
                sx={{
                  background: (theme) =>
                    currentStep === 2
                      ? theme.palette.color.purple
                      : theme.palette.color.lightShade,
                  borderRadius: "30px",
                  padding: "12px",
                  svg: {
                    width: "18px",
                    height: "auto",
                  },
                  "svg path, svg rect": {
                    stroke: (theme) =>
                      currentStep === 2
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,
                  },
                }}
              >
                <CardIcon />
              </Box>
              {currentStep === 2 && (
                <Stack>
                  <Typography
                    variant="subtitle2_500"
                    color={(theme) => theme.palette.color.purple}
                  >
                    Step 2/3
                  </Typography>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    Payment Details
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={1}
              sx={{
                marginRight: "12px",
                paddingRight: "12px",
              }}
            >
              <Box
                sx={{
                  background: (theme) =>
                    currentStep === 3
                      ? theme.palette.color.purple
                      : theme.palette.color.lightShade,
                  borderRadius: "30px",
                  padding: "12px",
                  "svg path": {
                    stroke: (theme) =>
                      currentStep === 3
                        ? theme.palette.color.darkText
                        : theme.palette.color.secondary,
                  },
                }}
              >
                <OrderIcon />
              </Box>
              {currentStep === 3 && (
                <Stack>
                  <Typography
                    variant="subtitle2_500"
                    color={(theme) => theme.palette.color.purple}
                  >
                    Step 3/3
                  </Typography>
                  <Typography
                    variant="heading_500"
                    color={(theme) => theme.palette.color.darkText}
                  >
                    Order Details
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Box>
        </Box> */}
        <Grid container spacing={2} sx={{
          mt: 4,
          ml: 2
        }}>
          <Grid item md={6} mdOffset={2}>
            <BillingDetails />
            <PaymentDetails />
          </Grid>
          <Grid item md={5}>
            <OrderSummary />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
