import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../../ui/CustomCard";
import DownloadIcon from "../../Icons/DownloadIcon";
import CertificateImage from "../../../assets/certificate.png";
import ShareArrowIcon from "../../Icons/ShareArrowIcon";

export default function Certificates() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Box>
            <CustomCard customPadding="0">
              <Stack>
                <Box>
                  <img src={CertificateImage} alt="" width={"100%"}/>
                </Box>
                <Box py={2} px={3}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        mb: 1
                    }}
                  >
                    <Typography variant="subHeaddingMedium">Proof of payout</Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <IconButton
                        sx={{
                          p: 0,
                          "svg path": {
                            stroke: (theme) => theme.palette.color.secondary
                          }
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          p: 0,
                          "svg path": {
                            stroke: (theme) => theme.palette.color.secondary
                          }
                        }}
                      >
                        <ShareArrowIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.skyColor}>+$1,050.10</Typography>
                  <Stack direction={"row"} alignItems={"center"} gap={1} sx={{
                    mt: 2
                  }}>
                    <Typography variant="subHeading">Account</Typography>
                    <Typography variant="subHeading" color={(theme) => theme.palette.color.darkText}>#105501</Typography>
                  </Stack>
                </Box>
              </Stack>
            </CustomCard>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box>
            <CustomCard customPadding="0">
              <Stack>
                <Box>
                  <img src={CertificateImage} alt="" width={"100%"} />
                </Box>
                <Box py={2} px={3}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        mb: 1
                    }}
                  >
                    <Typography variant="subHeaddingMedium">Proof of payout</Typography>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      <IconButton
                        sx={{
                          p: 0,
                          "svg path": {
                            stroke: (theme) => theme.palette.color.secondary
                          }
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          p: 0,
                          "svg path": {
                            stroke: (theme) => theme.palette.color.secondary
                          }
                        }}
                      >
                        <ShareArrowIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Typography variant="heading_500" color={(theme) => theme.palette.color.skyColor}>+$1,050.10</Typography>
                  <Stack direction={"row"} alignItems={"center"} gap={1} sx={{
                    mt: 2
                  }}>
                    <Typography variant="subHeading">Account</Typography>
                    <Typography variant="subHeading" color={(theme) => theme.palette.color.darkText}>#105501</Typography>
                  </Stack>
                </Box>
              </Stack>
            </CustomCard>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
