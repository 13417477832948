import DashboardIcon from "../components/Icons/Dashboard";
import ChartIcon from "../components/Icons/ChartIcon";
import CopyIcon from "../components/Icons/CopyIcon";
import BarChartIcon from "../components/Icons/BarChartIcon";
import InfoIcon from "../components/Icons/InfoIcon";

export const navItems = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    path: "/",
    tooltip: "Dashboard",
  },
  {
    title: "Metrics",
    icon: ChartIcon,
    path: "/metrics",
    tooltip: "Metrics",
    to: "/metrics",
  },
  {
    title: "Account Overview",
    icon: CopyIcon,
    path: "/accounts",
    tooltip: "Account Overview",
    to: "/accounts",
  },
  {
    title: "Leader Board",
    icon: BarChartIcon,
    path: "/leaderboard",
    tooltip: "Leader Board",
    to: "/leaderboard",
  },
];

export const navItems2 = [
  {
    title: "Billing Details",
    icon: InfoIcon,
    path: "/billing-details",
    tooltip: "Billing Details",
    to: "/billing-details",
  },
  {
    title: "Payment Details",
    icon: InfoIcon,
    path: "/payment-details",
    tooltip: "Payment Details",
    to: "/payment-details",
  },
  {
    title: "Start new evaluation",
    icon: InfoIcon,
    path: "/new-evaluation",
    tooltip: "Start new evaluation",
    to: "/new-evaluation",
  },
  {
    title: "Your Account",
    icon: InfoIcon,
    path: "/settings",
    tooltip: "Your Account",
    to: "/settings",
  },
  {
    title: "Order Details",
    icon: InfoIcon,
    path: "/order-details",
    tooltip: "Order Details",
    to: "/order-details",
  },
  {
    title: "Partner program",
    icon: InfoIcon,
    path: "/partner",
    tooltip: "Partner program",
    to: "/partner",
  },
];
