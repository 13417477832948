import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CustomTabs from "../components/ui/CustomTabs";
import LeaderboardTable from "../components/Leaderboard/leaderboardTable";
import UseSelect from "../components/ui/BaseSelect";

const tabsList = [
  {
    label: "All",
  },
  {
    label: "10K",
  },
  {
    label: "25K",
  },
  {
    label: "50K",
  },
  {
    label: "100K",
  },
  {
    label: "200K",
  },
];

const headcells = [
  {
    id: "symbol",
    label: "#",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.symbol}
      </Typography>
    ),
  },
  {
    id: "side",
    label: "Name",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.side}
      </Typography>
    ),
  },
  {
    id: "size",
    label: "Profit",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.size}
      </Typography>
    ),
  },
  {
    id: "market",
    label: "Equity",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.market}
      </Typography>
    ),
  },
  {
    id: "stop_loss",
    label: "Account Size",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.stop_loss}
      </Typography>
    ),
  },
  {
    id: "profit",
    label: "Gain %",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.profit}
      </Typography>
    ),
  },
  {
    id: "potential",
    label: "Country",
    getCell: (row) => (
      <Typography
        variant="subtitle2_500"
        color={(theme) => theme.palette.color.darkText}
      >
        {row.potential}
      </Typography>
    ),
  },
];

const data = [
  {
    symbol: "EUR.USD",
    side: "up",
    size: "0.05",
    market: "135.47",
    stop_loss: "135.47",
    profit: "135.47",
    potential: "1.5RR",
  },
  {
    symbol: "EUR.USD",
    side: "up",
    size: "0.05",
    market: "135.47",
    stop_loss: "135.47",
    profit: "135.47",
    potential: "1.5RR",
  },
];

export default function LeaderboardListing() {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Box
      mx={2}
      mt={5}
      mb={3}
      minHeight={"calc(100vh - 200px)"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <CustomTabs
            onChange={handleTabChange}
            value={selectedTab}
            tabs={tabsList}
          />
          <UseSelect
            options={[
              { value: "today", label: "Today" },
              { value: "yesterday", label: "Yesterday" },
              { value: "thisweek", label: "This Week" },
              { value: "lastweek", label: "Last Week" },
              { value: "thismonth", label: "This Month" },
              { value: "lastmonth", label: "Last Month" },
            ]}
            defaultValue="thisweek"
          />
        </Stack>
        <Box mt={4}>
          <LeaderboardTable headcells={headcells} rows={data} />
        </Box>
      </Box>
    </Box>
  );
}
