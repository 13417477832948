import {
  Box,
  Stack,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import AuthLayout from "../components/Login/AuthLayout";
import FormInput from "../components/ui/FormInput";
import EmailIcon from "../components/Icons/EmailIcon";
import PassHide from "../components/ui/PassHide";
import PassShow from "../components/ui/PassShow";
import GoogleIcon from "../components/Icons/GoogleIcon";
import AppleIcon from "../components/Icons/AppleIcon";
// import FormInput from "../components/FormInput"; // Adjust the path based on your file structure

export default function Login() {
  const [showPassword, setShowPassword] = useState("password");
  const handlePassVisiblety = () => {
    setShowPassword((prev) => (prev === "password" ? "text" : "password"));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Sign-up for Wefund
          </Typography>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ paddingTop: "24px", justifyContent: "center" }}
          >
            <button className="loginButton"><GoogleIcon /> Sign up with Google</button>
            <button className="loginButton"><AppleIcon /> Sign up with apple</button>
          </Stack>
          <div className="no-span section-divider">OR continue with</div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, maxWidth: "590px", width: "100%" }}
            >
              <Box paddingBottom={"16px"} sx={{ position: "relative" }}>
                <FormInput
                  label="Email"
                  name="email"
                  type="email"
                  required
                  fullWidth
                  placeholder="Enter your email"
                  sx={{
                    background: (theme) => theme.palette.color.lightShade,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.strockborder,
                    borderRadius: "16px",
                    pl: "32px",
                    fontSize: "14px",
                    color: "#FFFFFF",
                  }}
                />
                <Box
                  style={{ position: "absolute", bottom: "28px", left: "16px" }}
                >
                  <EmailIcon />
                </Box>
              </Box>
              <Box sx={{ position: "relative" }}>
                <FormInput
                  label="Password"
                  name="password"
                  type={showPassword}
                  required
                  fullWidth
                  placeholder="Enter your password"
                  sx={{
                    background: (theme) => theme.palette.color.lightShade,
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.color.strockborder,
                    borderRadius: "16px",
                    pr: "25px",
                  }}
                />
                <Box
                  style={{
                    position: "absolute",
                    bottom: "12px",
                    right: "16px",
                    cursor: "pointer",
                  }}
                  onClick={handlePassVisiblety}
                >
                  {showPassword === "password" ? <PassHide /> : <PassShow /> }
                </Box>
              </Box>
              <Grid container justifyContent="flex-end" sx={{ pb: "24px",pt: "4px" }}>
                <Grid item>
                  <Link
                    href="/forgot-password"
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.color.darkText,
                      fontSize: "14px",
                      lineHeight: "20px",
                      textDecoration: "none",
                    }}
                  >
                    Forgot Password ?
                  </Link>
                </Grid>
              </Grid>
              <button type="submit" fullWidth className="customAuthButton">
                Sign In
              </button>
              <Link href="/register" variant="body2" sx={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    paddingTop: "48px",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ color: (theme) => theme.palette.color.secondary }}
                  >
                    Don’t have an account yet?{" "}
                  </Typography>
                  <span sx={{ color: (theme) => theme.palette.color.skyColor }}>
                    Sign Up
                  </span>
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
