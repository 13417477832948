import React from "react";

export default function LaptopIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M3.5701 15.0587V6.27796C3.5701 5.91968 3.71242 5.57608 3.96576 5.32274C4.2191 5.0694 4.5627 4.92708 4.92098 4.92708H17.0789C17.4371 4.92708 17.7807 5.0694 18.0341 5.32274C18.2874 5.57608 18.4297 5.91968 18.4297 6.27796V15.0587"
        stroke="#4ED5FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.21921 15.0587H19.7806V16.4095C19.7806 16.7678 19.6383 17.1114 19.3849 17.3647C19.1316 17.6181 18.788 17.7604 18.4297 17.7604H3.57008C3.21181 17.7604 2.86821 17.6181 2.61487 17.3647C2.36153 17.1114 2.21921 16.7678 2.21921 16.4095V15.0587Z"
        stroke="#4ED5FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.3508 7.62883H9.64902"
        stroke="#4ED5FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
