import React from "react";

export default function EvaluatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.8866 9.95177H11.8967M4.09082 6.13248L4.35044 6.37283M7.57645 3.61333L7.89287 4.36989M12.4089 3.1377L11.9606 4.4064M17.2109 4.62748L15.6897 6.14871M4.534 11.9801C4.534 13.3922 5.09495 14.7464 6.09345 15.7449C7.09195 16.7434 8.4462 17.3044 9.85829 17.3044C11.2704 17.3044 12.6246 16.7434 13.6231 15.7449C14.6216 14.7464 15.1826 13.3922 15.1826 11.9801C15.1826 10.568 14.6216 9.21373 13.6231 8.21523C12.6246 7.21673 11.2704 6.65578 9.85829 6.65578C8.4462 6.65578 7.09195 7.21673 6.09345 8.21523C5.09495 9.21373 4.534 10.568 4.534 11.9801Z"
        stroke="#4ED5FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
