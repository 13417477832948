import React from "react";
import Chart from 'react-apexcharts';

export default function DonutChart() {
    const options = {
        chart: {
          type: 'donut',
          backgroundColor: "transparent"
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            donut: {
              size: '70%', 
            },
          },
        },
        labels: ['Market', 'Stop', 'Limit'],
        colors: ['#8b5cf6', '#3b82f6', '#1f2937'], 
        dataLabels: {
          enabled: false, 
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
      };
  const series = [30, 10, 60]; 
  
  return <Chart options={options} series={series} type="donut" width="380" />
}
