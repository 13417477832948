import React from "react";

export default function PolandIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_4662_755)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M28.0002 14C28.0002 21.7319 21.7322 28 14.0002 28C6.26831 28 0.000244141 21.7319 0.000244141 14"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_4662_755">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
