import {
  Box,
  IconButton,
  Input,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyIcon from "../../Icons/KeyIcon";
import CrossIcon from "../../Icons/CrossIcon";
import ColorCopyIcon from "../../Icons/ColorCopyIcon";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { copyContent } from "../../../utils/helpers";

export default function AccountCredentialModal({
  isOpen = false,
  onCloseModal = () => {},
}) {
  const [showPassword, setShowPassword] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    width: "100%",
    bgcolor: "background.paper",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.strockborder,
    boxShadow: 24,
    borderRadius: "16px",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "16px",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <KeyIcon />
              <Typography
                variant="h5"
                color={(theme) => theme.palette.color.darkText}
              >
                Account Credentials
              </Typography>
            </Stack>
            <IconButton
              sx={{
                background: (theme) => theme.palette.color.lightgray,
                borderRadius: "32px",
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText,
                },
              }}
              onClick={onCloseModal}
            >
              <CrossIcon />
            </IconButton>
          </Stack>
        </Box>
        <Box
          sx={{
            padding: "24px 16px",
          }}
        >
          <Stack direction={"column"} gap={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="subHeading">Account number</Typography>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  1050502040
                </Typography>
                <IconButton
                  sx={{
                    padding: 0,
                  }}
                  onClick={() => copyContent("1050502040")}
                >
                  <ColorCopyIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="subHeading">Password</Typography>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Input
                  type={showPassword ? "text" : "password"}
                  readOnly
                  value={"1234567800"}
                  className="without_border-input"
                />
                <IconButton
                  sx={{
                    padding: 0,
                  }}
                  onClick={() => copyContent("1234567800")}
                >
                  <ColorCopyIcon />
                </IconButton>
                <IconButton
                  sx={{
                    padding: 0,
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOutlined
                      sx={{
                        fontSize: "18px",
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    />
                  ) : (
                    <VisibilityOffOutlined
                      sx={{
                        fontSize: "18px",
                        color: (theme) => theme.palette.color.secondary,
                      }}
                    />
                  )}
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="subHeading">Platform</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Vuetra
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="subHeading">Server</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Vuetra cloud-demo
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
