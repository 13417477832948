import React from "react";
import CustomTabs from "../components/ui/CustomTabs";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomCard from "../components/ui/CustomCard";
import LinkIcon from "../components/Icons/LinkIcon";
import XIcon from "../components/Icons/XIcon";
import YoutubeIcon from "../components/Icons/YoutubeIcon";
import LinkedInIcon from "../components/Icons/LinkedInIcon";
import InstaIcon from "../components/Icons/InstaIcon";
import ColorCopyIcon from "../components/Icons/ColorCopyIcon";
import InfoIcon from "../components/Icons/InfoIcon";
import UpAngleArrowIcon from "../components/Icons/UpAngleArrowIcon";
import UseSelect from "../components/ui/BaseSelect";
import Linechart from "../components/PartnerProgram/Linechart";

const tabsList = [
  {
    label: "Overview",
  },
  {
    label: "Payout history"
  },
  {
    label: "Promotion material"
  }
]

export default function Partner() {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      mx={2}
      mt={5}
      mb={3}
      minHeight={"calc(100vh - 200px)"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <CustomTabs onChange={handleTabChange} value={selectedTab} tabs={tabsList}/>
          <UseSelect
            options={[
              { value: "today", label: "Today" },
              { value: "yesterday", label: "Yesterday" },
              { value: "thisweek", label: "This Week" },
              { value: "lastweek", label: "Last Week" },
              { value: "thismonth", label: "This Month" },
              { value: "lastmonth", label: "Last Month" },
            ]}
            defaultValue="thisweek"
          />
        </Stack>
        {selectedTab === 0 && (
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <CustomCard className="partner_cards" customPadding="24px 16px">
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}
                    height={"100%"}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={0.5}
                      sx={{
                        svg: {
                          width: "20px",
                        },
                      }}
                    >
                      <Typography variant="heading_500">
                        Commission unpaid
                      </Typography>
                      <InfoIcon />
                    </Stack>
                    <Typography variant="heading2">+$511.11</Typography>
                  </Box>
                </CustomCard>
              </Grid>
              <Grid item md={4}>
                <CustomCard className="partner_cards" customPadding="24px 16px">
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}
                    height={"100%"}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={0.5}
                      sx={{
                        svg: {
                          width: "20px",
                        },
                      }}
                    >
                      <Typography variant="heading_500">Total paid</Typography>
                      <InfoIcon />
                    </Stack>
                    <Typography variant="heading2">+$4 510.15</Typography>
                  </Box>
                </CustomCard>
              </Grid>
              <Grid item md={4}>
                <CustomCard customPadding="24px 16px">
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={0.5}
                    sx={{
                      svg: {
                        width: "20px",
                      },
                    }}
                  >
                    <Typography variant="heading_500">1.4</Typography>
                    <UpAngleArrowIcon />
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                  >
                    <Box>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={0.5}
                        sx={{
                          svg: {
                            width: "20px",
                          },
                        }}
                      >
                        <Typography variant="heading_500">
                          This month visitors
                        </Typography>
                        <InfoIcon />
                      </Stack>
                      <Typography variant="heading2">41</Typography>
                    </Box>
                    <Box width={"200px"}><Linechart /></Box>
                  </Stack>
                </CustomCard>
              </Grid>
              <Grid item md={4}>
                <CustomCard customPadding="24px 16px">
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={0.5}
                  >
                    <Typography variant="heading_500">1.4</Typography>
                    <UpAngleArrowIcon />
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                  >
                    <Box>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={0.5}
                        sx={{
                          svg: {
                            width: "20px",
                          },
                        }}
                      >
                        <Typography variant="heading_500">
                          This month leads
                        </Typography>
                        <InfoIcon />
                      </Stack>
                      <Typography variant="heading2">41</Typography>
                    </Box>
                    <Box width={"200px"}><Linechart /></Box>
                  </Stack>
                </CustomCard>
              </Grid>
              <Grid item md={4}>
                <CustomCard customPadding="24px 16px">
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={0.5}
                  >
                    <Typography variant="heading_500">1.4</Typography>
                    <UpAngleArrowIcon />
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                  >
                    <Box>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={0.5}
                        sx={{
                          svg: {
                            width: "20px",
                          },
                        }}
                      >
                        <Typography variant="heading_500">
                          This month conversions
                        </Typography>
                        <InfoIcon />
                      </Stack>
                      <Typography variant="heading2">41</Typography>
                    </Box>
                    <Box width={"200px"}><Linechart /></Box>
                  </Stack>
                </CustomCard>
              </Grid>
              <Grid item md={4}>
                <CustomCard customPadding="24px 16px">
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={0.5}
                  >
                    <Typography variant="heading_500">1.4</Typography>
                    <UpAngleArrowIcon />
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                  >
                    <Box>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={0.5}
                        sx={{
                          svg: {
                            width: "20px",
                          },
                        }}
                      >
                        <Typography variant="heading_500">
                          Conversion ratio
                        </Typography>
                        <InfoIcon />
                      </Stack>
                      <Typography variant="heading2">41</Typography>
                    </Box>
                    <Box width={"200px"}><Linechart /></Box>
                  </Stack>
                </CustomCard>
              </Grid>
            </Grid>
          </Box>
        )}
        {selectedTab === 1 && "payment"}
        {selectedTab === 2 && "promotion"}
      </Box>
      <Box mt={4}>
        <Stack direction={"row"} alignItems={"center"} gap={1} mb={1}>
          <LinkIcon />
          <Typography
            variant="h5"
            color={(theme) => theme.palette.color.darkText}
          >
            Tracking link
          </Typography>
        </Stack>
        <Typography variant="subHeading">
          Share your tracking link with your email list, followers or grandma!
        </Typography>
        <Stack direction={"row"} alignItems={"center"} gap={2} mt={1}>
          <Box position={"relative"}>
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              className="custom_input"
              fullWidth
              sx={{
                width: "660px",
              }}
              value={"https://firm.vuetra.com/join/partnerprogram"}
            />
            <Box position={"absolute"} right={"8px"} top={"15%"}>
              <IconButton onClick={() => {}}>
                <ColorCopyIcon />
              </IconButton>
            </Box>
          </Box>
          <IconButton onClick={() => {}}>
            <XIcon />
          </IconButton>
          <IconButton onClick={() => {}}>
            <YoutubeIcon />
          </IconButton>
          <IconButton onClick={() => {}}>
            <LinkedInIcon />
          </IconButton>
          <IconButton onClick={() => {}}>
            <InstaIcon />
          </IconButton>
        </Stack>
      </Box>
    </Box>
  );
}
