import Notifications from "./Notifications";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { getChildNavItem } from "../../../utils/getNavData";
import SearchSection from "./SearchSection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddIcon from "../../Icons/AddIcon";
import PrevIcon from "../../Icons/PrevIcon";

export default function Header() {
  const { pathname } = useLocation();
  const { navItem, childItem } = getChildNavItem(pathname);
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "7px 12px",
          color: (theme) => theme.palette.color.primary,
          backgroundColor: (theme) => theme.palette.color.bg,
          ...(childItem?.noHeader && { display: "none" }),
          ...(childItem && {
            px: { xs: "12px", lg: "10px" },
          }),
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          {id && <IconButton sx={{
            padding: 0,
            "svg path": {
              stroke: (theme) => theme.palette.color.darkText
            }
          }}><PrevIcon /></IconButton>}
          <Typography variant="heading2">
            {!!id ? id : navItem?.title}
          </Typography>
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, lg: 2 },
            }}
          >
            <Button
              startIcon={<AddIcon />}
              className="custom_color_btn customButton"
              onClick={() => navigate("/new-evaluation")}
            >
              New Evaluation
            </Button>
            <Notifications />
            <SearchSection />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
