import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AuthLayout from "../components/Login/AuthLayout";
import Link from "@mui/material/Link";
import FormInput from "../components/ui/FormInput";
import EmailIcon from "../components/Icons/EmailIcon";
import LeftArrow from "../components/Icons/LeftArrow";

export default function ForgotPass() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  return (
    <Stack sx={{ height: "100vh", overflow: "hidden" }} direction="row">
      <AuthLayout />
      <Box
        className="login-signup"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{ width: "590px", maxWidth: "100%", mx: "auto", px: 2 }}>
          <Typography
            variant="h1"
            className="signup-text"
            sx={{ textAlign: "center" }}
          >
            Forgot Password
          </Typography>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
              paddingTop: "24px",
              paddingBottom: "48px",
              fontSize: "16px",
            }}
          >
            <Typography
              variant="paragraph"
              sx={{
                textAlign: "center",
                color: (theme) => theme.palette.color.secondary,
              }}
            >
              No worries, we’ll send you reset instructions.
            </Typography>
          </Stack>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, maxWidth: "590px", width: "100%" }}
            >
              <Box paddingBottom={"16px"}>
                <Box sx={{ position: "relative" }}>
                  <FormInput
                    label="Email"
                    name="email"
                    type="email"
                    required
                    fullWidth
                    placeholder="Enter your email"
                    sx={{
                      background: (theme) => theme.palette.color.lightShade,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.color.strockborder,
                      borderRadius: "16px",
                      pl: "32px",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "12px",
                      left: "16px",
                    }}
                  >
                    <EmailIcon />
                  </Box>
                </Box>
              </Box>
              <button type="submit" style={{ marginTop: "12px" }} fullWidth className="customAuthButton">
                Reset Password
              </button>
              <Link
                href="/login"
                variant="body2"
                sx={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    paddingTop: "48px",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{ color: (theme) => theme.palette.color.secondary,
                     fontSize: "14px",
                     cursor: "pointer",
                     display: "flex",
                     alignItems: "center",
                     gap: "5px",
                     }}
                  >
                  <LeftArrow />  Back to login
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
