import React from "react";

export default function ShareArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.5576 13.968L17.5001 9.01805L12.5576 4.06055V13.968Z"
        fill="#8A96A6"
      />
      <path
        d="M12.565 10.6605L11.9875 10.578C11.65 10.533 11.1625 10.4655 10.5925 10.4355C9.95725 10.389 9.319 10.4092 8.6875 10.4955C8.335 10.542 7.987 10.6117 7.645 10.7055C7.2895 10.812 6.94375 10.9477 6.61 11.1105C6.268 11.274 5.9395 11.4645 5.6275 11.6805C5.3245 11.9092 5.03875 12.1605 4.7725 12.4305C4.49875 12.6802 4.2475 12.954 4.0225 13.248C3.811 13.5052 3.6205 13.7782 3.4525 14.0655C3.295 14.3355 3.1375 14.5755 3.0175 14.8155L2.7325 15.423L2.5 15.9405L2.5975 15.363L2.71 14.688C2.7625 14.433 2.8525 14.148 2.935 13.8255C3.02575 13.4797 3.1435 13.1407 3.2875 12.813C3.43075 12.4312 3.60625 12.063 3.8125 11.7105C4.0345 11.3205 4.285 10.9462 4.5625 10.593C5.16775 9.85197 5.887 9.21147 6.6925 8.69547C7.09225 8.44947 7.50775 8.23197 7.9375 8.04297C8.69425 7.71222 9.48325 7.46097 10.2925 7.29297C10.99 7.13547 11.5675 7.06047 11.9725 7.00047L12.5575 6.91797L12.565 10.6605Z"
        fill="#8A96A6"
      />
    </svg>
  );
}
