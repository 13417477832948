import React from "react";

export default function DollarCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25Z"
        stroke="#8A96A6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.25 8.75H11.375C10.477 8.75 9.75 9.478 9.75 10.375C9.75 11.273 10.477 12 11.375 12H12.625C13.523 12 14.25 12.727 14.25 13.625C14.25 14.523 13.523 15.25 12.625 15.25H9.75M12 7.75V8.25M12 15.75V16.25"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
