import React from "react";

export default function PostIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M4.4 4.4H17.6C18.5075 4.4 19.25 5.1425 19.25 6.05V15.95C19.25 16.8575 18.5075 17.6 17.6 17.6H4.4C3.4925 17.6 2.75 16.8575 2.75 15.95V6.05C2.75 5.1425 3.4925 4.4 4.4 4.4Z"
        stroke="#4ED5FF"
        stroke-width="1.2375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.25 6.05001L11 11.825L2.75 6.05001"
        stroke="#4ED5FF"
        stroke-width="1.2375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
