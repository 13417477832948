import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

export default function HelpCenterSidebar() {
  return (
    <Box sx={{ width: "300px", height: "100%" }}>
      <Box
        sx={{
          color: "#8A96A6",
          backgroundColor: "#0B0D0F",
          borderRadius: "16px",
          paddingBottom: "8px",
          height: "100%",
          mx: 2,
          border: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
          padding: 1.5,
        }}
      >
        <Box>
          <Typography variant="h2">
            We-Fund <span>DOCS</span>
          </Typography>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <IconButton
              sx={{
                padding: 0,
              }}
            >
              icon
            </IconButton>
            <Typography>Back to Dashboard</Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
