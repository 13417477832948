import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";

const data = {
  month: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    barData: [30, 40, 35, 50, 49, 60, 70, 91, 125, 65, 75, 85],
    lineData: [10, 50, 10, 90, 25, 80, 30, 70, 20, 60, 65, 70],
  },
  week: {
    categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    barData: [20, 40, 70, 80, 60, 70, 30],
    lineData: [10, 30, 10, 50, 40, 10, 20],
  },
};

export default function ChartComponent() {
  const [filter, setFilter] = useState("week");

  useEffect(() => {
    setFilter("week");
  }, [])

  const chartOptions = {
    chart: {
      type: "line",
      height: 330,
      toolbar: {
        show: false, // Disable the entire toolbar
      },
    },
    stroke: {
      width: [0, 2],
      curve: "smooth",
      colors: ["#4ED5FF"],
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        horizontal: false,
        endingShape: "rounded",
        borderRadius: 5,
      },
    },
    fill: {
      opacity: 1,
      colors: ["#303740", "#434D5B"],
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: ["#1C2025"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: "",
    xaxis: {
      position: "top",
      labels: {
        offsetY: -18,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    grid: {
      borderColor: "#f1f1f1",
      show: false,
    },
    toolbar: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  const series = [
    {
      name: "Bar Data",
      type: "column",
      data: data[filter].barData,
    },
    {
      name: "Line Data",
      type: "line",
      data: data[filter].lineData,
    },
  ];

  return (
    <>
      <ApexCharts
        options={chartOptions}
        series={series}
        type="line"
        height={340}
      />
    </>
  );
}