import React from "react";
import CustomCard from "../ui/CustomCard";
import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ChartIcon from "../Icons/ChartIcon";
import { Link } from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 16,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#754FFF",
  },
}));

export default function ActiveAccountDetails() {
  return (
    <CustomCard customPadding={"16px"}>
      <Box
        flexDirection={"column"}
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        sx={{
          ".image_div": {
            position: "absolute",
            right: "0",
            bottom: "0",
          },
        }}
      >
        <Box
          width={"100%"}
          sx={{
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
            pb: 2,
            mb: 3,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              flexDirection={"row"}
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#4ED5FF",
                  borderRadius: "50%",
                  height: "6px",
                  width: "6px",
                }}
              />
              <Typography
                variant="h5"
                color={(theme) => theme.palette.color.darkText}
                sx={{
                  a: {
                    textDecoration: "none",
                    color: (theme) => theme.palette.color.darkText 
                  }
                }}
              >
                <Link to={`/account/105501`}>#105501</Link>
              </Typography>
            </Stack>
            <ChartIcon />
          </Stack>
          <Typography variant="subtitle2">Challenge name</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stack
              flexDirection={"row"}
              sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
            >
              <Typography variant="subHeading">Start Date</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                March 12, 2024
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
            >
              <Typography variant="subHeading">Phase</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Phase 2
              </Typography>
            </Stack>
            <Stack
              flexDirection={"row"}
              sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
            >
              <Typography variant="subHeading">Balance</Typography>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                $105,460.15
              </Typography>
            </Stack>
          </Box>
          <Box mt={6} mb={2.5}>
            <BorderLinearProgress variant="determinate" value={50} />
            <Stack
              flexDirection={"row"}
              sx={{ justifyContent: "space-between", paddingBlock: "8px" }}
            >
              <Typography variant="subHeading">$105,460.15</Typography>
              <Typography variant="body1">56%</Typography>
            </Stack>
          </Box>
        </Box>
        <Button className="customButton see_all_btn metrix_btn" fullWidth>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={1.5}
          >
            <ChartIcon />
            <Typography
              variant="heading_500"
              color={(theme) => theme.palette.color.skyColor}
            >
              Metrics
            </Typography>
          </Stack>
        </Button>
      </Box>
    </CustomCard>
  );
}
