import React from "react";

export default function MobileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M16.077 17.9808V4.01923C16.077 3.31825 15.5088 2.75 14.8078 2.75L7.19241 2.75C6.49143 2.75 5.92318 3.31825 5.92318 4.01923L5.92318 17.9808C5.92318 18.6817 6.49143 19.25 7.19241 19.25H14.8078C15.5088 19.25 16.077 18.6817 16.077 17.9808Z"
        stroke="#4ED5FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0001 16.7115C11.5258 16.7115 11.952 16.2853 11.952 15.7596C11.952 15.2339 11.5258 14.8077 11.0001 14.8077C10.4744 14.8077 10.0482 15.2339 10.0482 15.7596C10.0482 16.2853 10.4744 16.7115 11.0001 16.7115Z"
        fill="#4ED5FF"
      />
    </svg>
  );
}
