import React from "react";

export default function RusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_4662_826)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M27.1292 18.8695C27.692 17.3529 28 15.7125 28 14C28 12.2875 27.692 10.6471 27.1292 9.13043H0.870789C0.308055 10.6471 0 12.2875 0 14C0 15.7125 0.308055 17.3529 0.870789 18.8695L14 20.0869L27.1292 18.8695Z"
          fill="#0052B4"
        />
        <path
          d="M14.0001 28C20.0196 28 25.1512 24.2008 27.1293 18.8696H0.87085C2.84895 24.2008 7.98055 28 14.0001 28Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_4662_826">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
