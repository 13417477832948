import React from "react";
import Slider from "react-slick";
import ActiveAccountDetails from "./ActiveAccountDetails";
import { styled } from "@mui/system";

export const Wrapper = styled("div")`
  .slick-slide {
    padding: 0 20px 0 0;
  }
  .slick-list {
    padding: 0 !important;
  }
`;

export default function ActiveAccounts() {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    centerMode: true,
    initialSlide: 1,
  };

  return (
    <Wrapper>
      <Slider {...settings}>
        <ActiveAccountDetails />
        <ActiveAccountDetails />
        <ActiveAccountDetails />
      </Slider>
    </Wrapper>
  );
}
