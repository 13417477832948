import React from "react";

export default function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.87533 8.95801H6.45866C6.01663 8.95801 5.59271 9.1336 5.28015 9.44616C4.96759 9.75872 4.79199 10.1826 4.79199 10.6247V14.3747C4.79199 14.8167 4.96759 15.2406 5.28015 15.5532C5.59271 15.8657 6.01663 16.0413 6.45866 16.0413H13.542C13.984 16.0413 14.4079 15.8657 14.7205 15.5532C15.0331 15.2406 15.2087 14.8167 15.2087 14.3747V10.6247C15.2087 10.1826 15.0331 9.75872 14.7205 9.44616C14.4079 9.1336 13.984 8.95801 13.542 8.95801H13.1253M10.0003 11.8747V3.95801M10.0003 3.95801L8.12533 6.04134M10.0003 3.95801L11.8753 6.04134"
        stroke="#8A96A6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
