import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelect, SelectProvider } from "@mui/base/useSelect";
import { useOption } from "@mui/base/useOption";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";

export default function UseSelect({ options, defaultValue }) {
  return (
    <CustomSelect
      placeholder="Select a color…"
      options={options}
      defaultValue={defaultValue}
    />
  );
}

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Root = styled("div")`
  position: relative;
`;

const Toggle = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-width: 125px;
  padding: 8px 12px;
  border-radius: 32px;
  text-align: left;
  line-height: 1.5;
  overflow: hidden;
  background:  transparent;
  border: 1px solid;
  border-color:  ${grey[900]};
  color: ${grey[400]};
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  cursor: pointer;


  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:focus-visible {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
);

const ToggleIcon = styled("span")(
  ({ theme }) => `
   position: absolute;
   right: 0;
   top: 50%;
   transform: translateY(-50%);
   display: flex;
   height: 100%;
   width: 20px;
   background: ${theme.palette.color.bgGrey};
   padding-left: 25px;
    `
);

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 160px;
  padding: 12px;
  border-radius: 12px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  padding: 5px;
  margin: 5px 0 0 0;
  position: absolute;
  height: auto;
  width: 100%;
  overflow: auto;
  z-index: 1;
  outline: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  box-shadow: 0px 2px 6px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  left: -40px;
  cursor: pointer;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s step-end;
  }
  `
);

const Option = styled("li")(
  ({ theme }) => `
  padding: 10px 8px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;

  &[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]}; 
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.highlighted,
  &:hover {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &[aria-selected='true'].highlighted {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
    font-size: 13px;
    color: white;
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }
  `
);

function renderSelectedValue(value, options) {
  const selectedOption = options.find((option) => option.value === value);

  return selectedOption ? `${selectedOption.label}` : null;
}

function CustomOption(props) {
  const { children, value, className, disabled = false, selectedValue } = props;
  const { getRootProps, highlighted } = useOption({
    value,
    disabled,
    label: children,
  });

  return (
    <Option
      {...getRootProps()}
      className={clsx({ highlighted }, className)}
      style={{ "--color": value }}
    >
      {value === selectedValue ? <CheckIcon fontSize="small" /> : ""} {children}
    </Option>
  );
}

CustomOption.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

function CustomSelect({ options, placeholder, defaultValue }) {
  const listboxRef = React.useRef(null);
  const [listboxVisible, setListboxVisible] = React.useState(false);

  const { getButtonProps, getListboxProps, contextValue, value } = useSelect({
    listboxRef,
    onOpenChange: setListboxVisible,
    open: listboxVisible,
    defaultValue: defaultValue || options[0].value,
  });

  return (
    <Root>
      <Toggle {...getButtonProps()} style={{ "--color": value }}>
        {renderSelectedValue(value, options) || (
          <span className="placeholder">{placeholder ?? " "}</span>
        )}

        <ToggleIcon>
          <ExpandMoreIcon
            sx={{
              color: (theme) => theme.palette.color.secondary,
              fontSize: "15px",
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        </ToggleIcon>
      </Toggle>
      <Listbox
        {...getListboxProps()}
        aria-hidden={!listboxVisible}
        className={listboxVisible ? "" : "hidden"}
      >
        <SelectProvider value={contextValue}>
          {options.map((option) => {
            return (
              <CustomOption
                key={option.value}
                value={option.value}
                selectedValue={value}
              >
                {option.label}
              </CustomOption>
            );
          })}
        </SelectProvider>
      </Listbox>
    </Root>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
};
