import { Box, Typography, Stack } from "@mui/material";
import React from "react";
import popArr from "../../assets/popArr.svg";

function PopoverHelp() {
  return (
    <Box sx={{ transition: "all 500ms ease-in-out" }}>
      <Box
        padding={"14px"}
        sx={{
          background: "linear-gradient(135deg, #55AFFF 0%, #6A52FF 100%)",
          fontSize: "24px",
          lineHeight: "32px",
          fontWeight: 500,
        }}
      >
        <Typography variant="paragraph">Help center</Typography>
        {/* <Searchbar /> */}
      </Box>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box paddingRight={"9px"}>
          <img src={popArr} alt="" />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box paddingRight={"9px"}>
          <img src={popArr} alt="" />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box paddingRight={"9px"}>
          <img src={popArr} alt="" />
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          padding: "14px",
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.color.strockborder,
        }}
        backgroundColor={(theme) => theme.palette.color.bgGrey}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"}>
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }} variant="h6">
              Title collection
            </Typography>
            <Typography
              sx={{
                marginTop: "8px",
                fontSize: "12px",
                color: (theme) => theme.palette.color.secondary,
              }}
              variant="h6"
            >
              This is the description 21 articles
            </Typography>
          </Box>
        </Stack>
        <Box paddingRight={"9px"}>
          <img src={popArr} alt="" />
        </Box>
      </Stack>
    </Box>
  );
}

export default PopoverHelp;
