import React from "react";

export default function ChartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 5.69995C8.52095 5.69995 5.69995 8.52095 5.69995 12C5.69995 15.479 8.52095 18.3 12 18.3C15.479 18.3 18.3 15.479 18.3 12M12 5.69995C15.479 5.69995 18.3 8.52095 18.3 12M12 5.69995V12H18.3"
        stroke="#8A96A6"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
