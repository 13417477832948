import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyIcon from "../Icons/KeyIcon";
import CrossIcon from "../Icons/CrossIcon";

export default function PasswordChangeModal({ isOpen, onCloseModal }) {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isOtpForm, setIsOtpForm] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    width: "100%",
    bgcolor: "background.paper",
    border: "1px solid",
    borderColor: (theme) => theme.palette.color.strockborder,
    boxShadow: 24,
    borderRadius: "16px",
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "16px",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <KeyIcon />
              <Typography
                variant="h5"
                color={(theme) => theme.palette.color.darkText}
              >
                {isOtpForm ? "Verify Password" : "Change Password"}
              </Typography>
            </Stack>
            <IconButton
              sx={{
                background: (theme) => theme.palette.color.lightgray,
                borderRadius: "32px",
                "svg path": {
                  stroke: (theme) => theme.palette.color.darkText,
                },
              }}
              onClick={onCloseModal}
            >
              <CrossIcon />
            </IconButton>
          </Stack>
        </Box>
        {!isOtpForm && (
          <Box
            sx={{
              p: "24px",
            }}
          >
            <Box>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Old Password
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                  input: {
                    background: (theme) =>
                      theme.palette.color.blackShade + "! important",
                  },
                }}
              />
            </Box>
            <Box mt={2}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                New Password
              </Typography>
              <Input
                type={!showNewPassword ? "text" : "password"}
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {!showNewPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box mt={2}>
              <Typography
                variant="subtitle2"
                color={(theme) => theme.palette.color.red}
              >
                Please add all necessary characters to create a safe password
              </Typography>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) => theme.palette.color.secondary,
                  }}
                />
                <Typography variant="subtitle2">
                  Minimum characters 12
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) => theme.palette.color.secondary,
                  }}
                />
                <Typography variant="subtitle2">
                  One uppercase character
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) => theme.palette.color.secondary,
                  }}
                />
                <Typography variant="subtitle2">
                  One lowercase character
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) => theme.palette.color.secondary,
                  }}
                />
                <Typography variant="subtitle2">
                  One special character
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Box
                  sx={{
                    width: 6,
                    height: 6,
                    borderRadius: "505%",
                    background: (theme) => theme.palette.color.secondary,
                  }}
                />
                <Typography variant="subtitle2">One numbers</Typography>
              </Stack>
            </Box>
            <Box mt={2}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Confirm New Password
              </Typography>
              <Input
                type={showConfirmPassword ? "text" : "password"}
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
        )}
        {isOtpForm && (
          <Box
            minHeight={450}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box textAlign={"center"}>
              <Stack direction={"column"} gap={0.5}>
                <Typography variant="h5" color={(theme) => theme.palette.color.darkText}>Verification</Typography>
                <Typography variant="subtitle2">
                  Enter the 6-digit code sent to j*****@****oud.com
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1} sx={{
                mt: 3,
                mb: 4
              }}>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="custom_input otp_fields"
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="custom_input otp_fields"
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="custom_input otp_fields"
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="custom_input otp_fields"
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="custom_input otp_fields"
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  className="custom_input otp_fields"
                  sx={{
                    mt: "8px",
                    input: {
                      background: (theme) =>
                        theme.palette.color.blackShade + "! important",
                    },
                  }}
                />
              </Stack>
              <Box>
                <Stack direction={"row"} alignItems={"center"} gap={1} justifyContent={"center"}>
                  <Button
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: (theme) => theme.palette.color.darkText,
                      textTransform: "capitalize",
                      p:0
                    }}
                  >
                    Resend Code
                  </Button>
                  <Typography variant="subtitle2">(59s)</Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            p: "16px",
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={3.5}
          >
            <Button
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: (theme) => theme.palette.color.secondary,
                textTransform: "capitalize",
              }}
              onClose={onCloseModal}
            >
              Cancel
            </Button>
            {isOtpForm ? (
              <Button
                className="custom_color_btn customButton"
                onClick={() => setIsOtpForm(false)}
              >
                Verify
              </Button>
            ) : (
              <Button
                className="custom_color_btn customButton"
                onClick={() => setIsOtpForm(true)}
              >
                Change Password
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
