import {
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { notifications } from "../../../static/header";
import PendingNotification from "./Notifications/PendingNotification";
import ActivityNotification from "./Notifications/ActivityNotification";
import NotificationIcon from "../../Icons/NotificationIcon";

const iconStyle = {
  color: (theme) => theme.palette.color.secondary,
  fontSize: "22px",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Notifications() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <IconButton onClick={handleOpen} className="custom_btn_icon">
        <Badge color="secondary" variant="dot">
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            height: "fit-content",
            padding: "24px",
          },
        }}
        open={open}
        onClose={handleClose}
        anchor="right"
      >
        <Paper
          sx={{
            width: "460px",
            height: "100%",
            overflow: "hidden",
            background: (theme) => theme.palette.color.bg5,
            border: (theme) => `1px solid ${theme.palette.color.strockborder}`,
            borderRadius: "24px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              padding: "18px 24px",
            }}
          >
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Typography variant="h6">Notifications </Typography>
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  background: (theme) => theme.palette.color.lightgray,
                  borderRadius: "4px",
                  border: `1px solid #15191F`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="heading">11</Typography>
              </Box>
            </Box>

            <IconButton onClick={handleClose}>
              <CloseIcon sx={iconStyle} />
            </IconButton>
          </Stack>
          {/* <Divider sx={{ my: 1 }} /> */}
          <Box
            sx={{
              width: "100%",
              borderBottom: (theme) =>
                `1px solid ${theme.palette.color.border}`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pl: "16px",
              pr: "18px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Actionables" {...a11yProps(0)} />
                <Tab label="Activity" {...a11yProps(1)} />
                <Tab label="All" {...a11yProps(2)} />
              </Tabs>
              <Divider orientation="vertical" flexItem />
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  {...a11yProps(0)}
                  sx={{
                    display: "none",
                  }}
                />
                <Tab
                  {...a11yProps(1)}
                  sx={{
                    display: "none",
                  }}
                />
                <Tab
                  {...a11yProps(2)}
                  sx={{
                    display: "none",
                  }}
                />

                <Tab label="Completed" {...a11yProps(3)} />
              </Tabs>
            </Box>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
            >
              <path
                d="M6.9375 5.3252C8.07659 5.3252 9 4.40178 9 3.2627C9 2.12361 8.07659 1.2002 6.9375 1.2002C5.79841 1.2002 4.875 2.12361 4.875 3.2627C4.875 4.40178 5.79841 5.3252 6.9375 5.3252Z"
                stroke="#8A96A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.4375 13.5752C13.5766 13.5752 14.5 12.6518 14.5 11.5127C14.5 10.3736 13.5766 9.4502 12.4375 9.4502C11.2984 9.4502 10.375 10.3736 10.375 11.5127C10.375 12.6518 11.2984 13.5752 12.4375 13.5752Z"
                stroke="#8A96A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 3.2627H16.5625"
                stroke="#8A96A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.4375 3.2627H4.875"
                stroke="#8A96A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 11.5127H16.5625"
                stroke="#8A96A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.4375 11.5127H10.375"
                stroke="#8A96A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>

          <Stack
            sx={{
              height: "500px",
              overflowY: "auto",
              px: "20px",
            }}
            spacing={1}
          >
            {notifications
              .filter(
                (n) =>
                  n.notifications.filter((notification) => {
                    if (value === 0) return !notification.complete;
                    if (value === 1)
                      return (
                        notification.status !== "pending" &&
                        !notification.complete
                      );
                    if (value === 2) return !notification.complete;
                    if (value === 3) return notification.complete;
                    else return true;
                  }).length > 0
              )
              .map((n) => (
                <Stack spacing={0}>
                  <Typography variant="caption_500" sx={{ pt: "20px" }}>
                    {n.time}
                  </Typography>
                  {n.notifications
                    .filter((notification) => {
                      if (value === 0) return !notification.complete;
                      if (value === 1)
                        return (
                          notification.status !== "pending" &&
                          !notification.complete
                        );
                      if (value === 2) return !notification.complete;
                      if (value === 3) return notification.complete;
                      else return true;
                    })
                    .map((notification) =>
                      notification.status === "pending" ? (
                        <PendingNotification notification={notification} />
                      ) : (
                        !notification.complete && (
                          <ActivityNotification notification={notification} />
                        )
                      )
                    )}
                </Stack>
              ))}
          </Stack>
        </Paper>
      </Drawer>
    </Box>
  );
}
