import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import PayoutsIcon1 from "../../../Icons/Payouts1";
import CheckIcon from "../../../Icons/Check";
import SupportIcon from "../../../Icons/Support";
import FlagIcon from "../../../Icons/Flag";

const PendingNotification = ({ notification }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {notification.type === "payout" && <PayoutsIcon1 width={20} />}
          {notification.type === "ticket" && <SupportIcon width={20} />}
          {notification.type === "copyTrader" && <FlagIcon width={20} />}
          {notification.type === "waiting" && (
            <Box sx={{ width: "16px" }}>
              <img
                src={"/images/waiting.avif"}
                alt="user"
                style={{ width: "100%" }}
              />
            </Box>
          )}
          <Typography variant="heading" sx={{ ml: 1 }}>
            {notification.type === "payout" && "New Payout Request"}
            {notification.type === "ticket" && "New Ticket Assigned"}
            {notification.type === "copyTrader" && "Copy Trader Detected"}
            {notification.type === "waiting" &&
              notification.customer + " is waiting for you"}
          </Typography>
        </Box>
        <Typography variant="body3">· 5m ago</Typography>
      </Box>
      <Stack
        spacing={3}
        sx={{
          pl: "26px",
          pt: "6px",
        }}
      >
        <Typography
          variant="caption_500"
          sx={{
            "& span": {
              color: (theme) => theme.palette.color.primary,
              mx: "6px",
            },
          }}
        >
          {notification.type === "payout" && "User"}
          {notification.type === "ticket" && "Ticket"}
          <span>{notification.user || notification.ticket}</span>
          {notification.type === "payout" && "requested a payout of US$"}
          {notification.type === "ticket" && "is assigned to you"}
          {notification.type === "copyTrader" && "has been flagged for"}
          {notification.type === "waiting" && "is waiting for you to respond"}
          <span>{notification.amount}</span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "6px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{
              padding: "7px 20px",
              background: (theme) => theme.palette.color.borderpure,
              color: (theme) =>
                theme.palette.color[
                  ["payout", "ticket"].includes(notification.type)
                    ? "red"
                    : "active"
                ],
              borderRadius: "50px",
              fontSize: "11.5px",
              fontWeight: "500",
              lineHeight: "18.9px",
              width: "85px",
              ":hover": {
                background: (theme) => theme.palette.color.borderpure,
                color: (theme) =>
                  alpha(
                    theme.palette.color[
                      ["payout", "ticket"].includes(notification.type)
                        ? "red"
                        : "active"
                    ],
                    0.5
                  ),
              },
            }}
          >
            {["payout", "ticket"].includes(notification.type) && "Reject"}
            {notification.type === "waiting" && "Respond"}
            {notification.type === "copyTrader" && "Review"}
          </Button>
          {["payout", "ticket"].includes(notification.type) && (
            <Button
              variant="contained"
              size="small"
              sx={{
                padding: "4px 16px 4px 8px",
                background: (theme) => theme.palette.color.borderpure,
                borderRadius: "50px",
                fontSize: "11.5px",
                fontWeight: "500",
                lineHeight: "18.9px",
                width: "85px",
                transition: "all 0.5s ease-in-out",
                color: (theme) => theme.palette.color.active,
                ":hover": {
                  background: (theme) => theme.palette.color.borderpure,
                  color: (theme) => alpha(theme.palette.color.active, 0.5),
                },
              }}
              startIcon={<CheckIcon stroke={theme.palette.color.active} />}
            >
              Resolve
            </Button>
          )}
        </Box>
        <Divider />
      </Stack>
    </Box>
  );
};

export default PendingNotification;
