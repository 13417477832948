export const accountsData = [
    {
        status: "Active",
        id: "105501",
        phase: 2
    },
    {
        status: "InActive",
        id: "105502",
        phase: 2
    },
    {
        status: "InActive",
        id: "105520",
        phase: 2
    },
    {
        status: "Failed",
        id: "105554",
        phase: 2
    },
    {
        status: "Failed",
        id: "105541",
        phase: 2
    }
    ,
    {
        status: "Active",
        id: "105542",
        phase: 2
    }
    ,
    {
        status: "Failed",
        id: "105548",
        phase: 2
    }
]
