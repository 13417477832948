import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import CustomCard from "../ui/CustomCard";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import MuiPhoneNumber from "mui-phone-number";
import PencilIcon from "../Icons/PencilIcon";

export default function Profile() {
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [imagePreview, setImagePreview] = useState("");
  const fileInputRef = useRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const validateImage = (event) => {
    const fileInput = event.target;
    const filePath = fileInput.value;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert(
        "Please upload a file having extensions .jpeg/.jpg/.png/.gif only."
      );
      fileInput.value = "";
      setImagePreview("");
      return false;
    } else {
      // Image preview
      if (fileInput.files && fileInput.files[0]) {
        const reader = new FileReader();
        reader.onload = function (e) {
          setImagePreview(e.target.result);
        };
        reader.readAsDataURL(fileInput.files[0]);
      }
    }
  };

  return (
    <Box>
      <CustomCard>
        <Grid container columnSpacing={5} spacing={2}>
          <Grid item md={5}>
            <Typography
              variant="heading_500"
              color={(theme) => theme.palette.color.darkText}
            >
              First name
            </Typography>
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              className="custom_input"
              fullWidth
              sx={{
                mt: "8px",
                input: {
                  background: (theme) =>
                    theme.palette.color.blackShade + "! important",
                },
              }}
            />
          </Grid>
          <Grid item md={5}>
            <Typography
              variant="heading_500"
              color={(theme) => theme.palette.color.darkText}
            >
              Last name
            </Typography>
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              className="custom_input"
              fullWidth
              sx={{
                mt: "8px",
                input: {
                  background: (theme) =>
                    theme.palette.color.blackShade + "! important",
                },
              }}
            />
          </Grid>
          <Grid item md={2}>
            <Box position={"relative"}>
              <Box
                borderRadius={"50%"}
                overflow={"hidden"}
                width={"96px"}
                height={"96px"}
                backgroundColor={"#fff"}
                sx={{
                  overflow: "hidden",
                }}
              >
                {imagePreview && (
                  <img src={imagePreview} alt="Preview" style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}/>
                )}
              </Box>
              <IconButton
                sx={{
                  borderRadius: "50%",
                  background: (theme) => theme.palette.color.lightgray,
                  position: "absolute",
                  bottom: 0,
                  ":hover": {
                    background: (theme) => theme.palette.color.lightgray,
                  },
                }}
                onClick={handleButtonClick}
              >
                <PencilIcon />
              </IconButton>
              <input
                type="file"
                id="imageUpload"
                name="imageUpload"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={validateImage}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomCard>
      <Box mt={3}>
        <CustomCard>
          <Grid container columnSpacing={5} rowSpacing={2}>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Email
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                  input: {
                    background: (theme) =>
                      theme.palette.color.blackShade + "! important",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Box>
                <Typography
                  variant="heading_500"
                  color={(theme) => theme.palette.color.darkText}
                >
                  Phone
                </Typography>
              </Box>
              <MuiPhoneNumber
                defaultCountry={"nl"}
                onChange={(e) => console.log(e)}
                inputClass="custom_phone_input"
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Company name (optional)
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                  input: {
                    background: (theme) =>
                      theme.palette.color.blackShade + "! important",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                EU VAT Number (optional)
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                  input: {
                    background: (theme) =>
                      theme.palette.color.blackShade + "! important",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Country
              </Typography>
              <CountrySelect
                onChange={(e) => {
                  setCountryid(e.id);
                }}
                showFlag={false}
                placeHolder="Select Country"
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                State
              </Typography>
              <StateSelect
                countryid={countryid}
                onChange={(e) => {
                  setstateid(e.id);
                }}
                placeHolder="Select State"
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                City
              </Typography>
              <CitySelect
                countryid={countryid}
                stateid={stateid}
                onChange={(e) => {
                  console.log(e);
                }}
                placeHolder="Select City"
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Address
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                  input: {
                    background: (theme) =>
                      theme.palette.color.blackShade + "! important",
                  },
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Postal Code
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                  input: {
                    background: (theme) =>
                      theme.palette.color.blackShade + "! important",
                  },
                }}
              />
            </Grid>
          </Grid>
        </CustomCard>
      </Box>
      <Box mt={3} textAlign={"right"}>
        <Button className="custom_color_btn customButton">Save Changes</Button>
      </Box>
    </Box>
  );
}
