import React from "react";

export default function JapanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_4662_832)">
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill="#F0F0F0"
        />
        <path
          d="M14.0001 20.087C17.3618 20.087 20.087 17.3617 20.087 14C20.087 10.6383 17.3618 7.91309 14.0001 7.91309C10.6384 7.91309 7.91315 10.6383 7.91315 14C7.91315 17.3617 10.6384 20.087 14.0001 20.087Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_4662_832">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
