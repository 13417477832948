import React from "react";

export default function CrossSquareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.12516 11.8757L11.8752 8.12565M11.8752 11.8757L8.12516 8.12565M14.3752 16.0423H5.62516C4.70433 16.0423 3.9585 15.2965 3.9585 14.3757V5.62565C3.9585 4.70482 4.70433 3.95898 5.62516 3.95898H14.3752C15.296 3.95898 16.0418 4.70482 16.0418 5.62565V14.3757C16.0418 15.2965 15.296 16.0423 14.3752 16.0423Z"
        stroke="#754FFF"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
