import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../components/ui/CustomCard";
import CardIcon from "../components/Icons/CardIcon";
import BankIcon from "../components/Icons/BankIcon";
import StripeIcon from "../components/Icons/StripeIcon";
import PaypalIcon from "../components/Icons/PaypalIcon";

export default function PaymentDetails() {
  return (
    <Box mt={2}>
      <Box
        sx={{
          background: (theme) => theme.palette.color.bgGrey,
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "21px 24px",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.color.strockborder,
            background: (theme) => theme.palette.color.lightShade,
          }}
        >
          <Typography variant="subHeaddingMedium">Payment method</Typography>
        </Box>
        <Box
          sx={{
            padding: "24px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color={(theme) => theme.palette.color.darkText}
              >
                Choose Payment method
              </Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={2}
                mt={2}
                mb={2}
              >
                <CustomCard
                  customPadding={"0"}
                  className="activePayment payment_method-cards"
                >
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CardIcon />
                    <Typography>Card</Typography>
                  </Box>
                </CustomCard>
                <CustomCard
                  customPadding={"0"}
                  className="payment_method-cards"
                >
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BankIcon />
                    <Typography>Bank transfer</Typography>
                  </Box>
                </CustomCard>
                <CustomCard
                  customPadding={"0"}
                  className="payment_method-cards"
                >
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <StripeIcon />
                  </Box>
                </CustomCard>
                <CustomCard
                  customPadding={"0"}
                  className="payment_method-cards"
                >
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PaypalIcon />
                  </Box>
                </CustomCard>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Name on Card
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Card number
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
                placeholder="XXXX-XXXX-XXXX-XXXX"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Expiry Date
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
                placeholder="MM/YY"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="heading_500"
                color={(theme) => theme.palette.color.darkText}
              >
                Security Code
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                className="custom_input"
                fullWidth
                sx={{
                  mt: "8px",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
