import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import Logo from "../../assets/logo.png";

export default function Footer() {
  return (
    <Box p={1.5}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Box maxWidth={"105px"} sx={{
                "img": {
                    width: "100%"
                }
            }}>
                <img src={Logo} alt="wefund" />
            </Box>
            <Box>
                <Stack direction={"row"} alignItems={"center"} gap={3}>
                    <Typography color={(theme) => theme.palette.color.secondary}>Privacy Policy</Typography>
                    <Typography color={(theme) => theme.palette.color.secondary}>Risk Disclosure</Typography>
                    <Typography color={(theme) => theme.palette.color.secondary}>Performance Disclosure</Typography>
                    <Typography color={(theme) => theme.palette.color.secondary}>Trading Disclaimer</Typography>
                    <Typography color={(theme) => theme.palette.color.secondary}>Terms & Conditions</Typography>
                    <Typography color={(theme) => theme.palette.color.secondary}>Data/Tech/Platform Disclaimer</Typography>
                </Stack>
            </Box>
        </Stack>
    </Box>
  )
}
