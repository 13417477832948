import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTabs from "../../ui/CustomTabs";
import CustomTable from "./CustomTable";

const tabsList = [
  {
    label: "Overview",
  },
  {
    label: "Open",
  },
  {
    label: "Pending",
  },
  {
    label: "Closed",
  },
];

const headcells = [
  {
    id: "symbol",
    label: "Symbol",
    getCell: (row) => row.symbol,
  },
  {
    id: "side",
    label: "Side",
    getCell: (row) => row.side,
  },
  {
    id: "size",
    label: "Size",
    getCell: (row) => row.size,
  },
  {
    id: "market",
    label: "Entry/Market",
    getCell: (row) => <p>{row.market}</p>,
  },
  {
    id: "stop_loss",
    label: "Stop Loss",
    getCell: (row) => row.stop_loss,
  },
  {
    id: "profit",
    label: "Take Profit",
    getCell: (row) => row.profit,
  },
  {
    id: "potential",
    label: "Potential R:R",
    getCell: (row) => row.potential,
  },
  {
    id: "exposure",
    label: "Exposure",
    getCell: (row) => row.exposure,
  },
  {
    id: "created_at",
    label: "Created At",
    getCell: (row) => row.created_at,
  },
  {
    id: "fee",
    label: "Fee",
    getCell: (row) => row.fee,
  },
  {
    id: "pl",
    label: "P/L",
    getCell: (row) => row.pl,
  },
];

const pendingHeadcells = [
  {
    id: "symbol",
    label: "Symbol",
    getCell: (row) => row.symbol,
  },
  {
    id: "side",
    label: "Side",
    getCell: (row) => row.side,
  },
  {
    id: "size",
    label: "Size",
    getCell: (row) => row.size,
  },
  {
    id: "created_at",
    label: "Created At",
    getCell: (row) => row.created_at,
  },
  {
    id: "open_price",
    label: "Open Price",
    getCell: (row) => row.open_price,
  },
  {
    id: "stop_loss",
    label: "Stop Loss",
    getCell: (row) => row.stop_loss,
  },
  {
    id: "profit",
    label: "Take Profit",
    getCell: (row) => row.profit,
  },
  {
    id: "order",
    label: "Order",
    getCell: (row) => row.order,
  },
  {
    id: "type",
    label: "Type",
    getCell: (row) => row.type,
  },
];

const data = [
  {
    symbol: "EUR.USD",
    side: "up",
    size: "0.05",
    market: "135.47",
    stop_loss: "135.47",
    profit: "135.47",
    potential: "1.5RR",
    exposure: "$5,000",
    created_at: "2023/05/03 16:19:41",
    fee: "$0.35",
    pl: "+$3412.13",
  },
  {
    symbol: "EUR.USD",
    side: "up",
    size: "0.05",
    market: "135.47",
    stop_loss: "135.47",
    profit: "135.47",
    potential: "1.5RR",
    exposure: "$5,000",
    created_at: "2023/05/03 16:19:41",
    fee: "$0.35",
    pl: "+$3412.13",
  },
];

const pendingData = [
  {
    symbol: "EUR.USD",
    side: "up",
    size: "0.05",
    open_price: "135.47",
    stop_loss: "135.47",
    profit: "135.47",
    potential: "1.5RR",
    exposure: "$5,000",
    created_at: "2023/05/03 16:19:41",
    order: "$0.35",
    type: "+$3412.13",
  },
  {
    symbol: "EUR.USD",
    side: "up",
    size: "0.05",
    open_price: "135.47",
    stop_loss: "135.47",
    profit: "135.47",
    potential: "1.5RR",
    exposure: "$5,000",
    created_at: "2023/05/03 16:19:41",
    order: "$0.35",
    type: "+$3412.13",
  },
];

export default function Position() {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Box>
      <CustomTabs
        onChange={handleTabChange}
        value={selectedTab}
        tabs={tabsList}
      />
      <Box mt={2}>
        {selectedTab === 0 && (
          <Box>
            <CustomTable headcells={headcells} rows={data} />
            <Box mt={4}>
              <Typography
                variant="heading"
                color={(theme) => theme.palette.color.darkText}
                sx={{
                  mb: 2,
                }}
              >
                Pending positions
              </Typography>
              <CustomTable headcells={pendingHeadcells} rows={pendingData} />
            </Box>
          </Box>
        )}
        {selectedTab === 1 && (
          <CustomTable headcells={pendingHeadcells} rows={pendingData} />
        )}
        {selectedTab === 2 && (
          <CustomTable headcells={pendingHeadcells} rows={pendingData} />
        )}
        {selectedTab === 3 && <CustomTable headcells={headcells} rows={data} />}
      </Box>
    </Box>
  );
}
