import React from "react";

function AppleIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.881 0.916672C14.9279 0.916672 14.9749 0.916672 15.0245 0.916672C15.1396 2.33887 14.5967 3.40153 13.937 4.17107C13.2897 4.9353 12.4032 5.67651 10.9695 5.56405C10.8739 4.16222 11.4176 3.17837 12.0765 2.4106C12.6875 1.69507 13.8077 1.05836 14.881 0.916672Z"
        fill="white"
      />
      <path
        d="M19.2211 15.7196C19.2211 15.7337 19.2211 15.7461 19.2211 15.7594C18.8181 16.9797 18.2434 18.0255 17.5421 18.9961C16.9018 19.8772 16.1172 21.063 14.7163 21.063C13.5057 21.063 12.7016 20.2846 11.461 20.2633C10.1486 20.2421 9.42686 20.9142 8.22693 21.0833C8.08967 21.0833 7.95241 21.0833 7.81781 21.0833C6.93669 20.9558 6.22559 20.258 5.70754 19.6293C4.17996 17.7714 2.99952 15.3715 2.77991 12.3004C2.77991 11.9994 2.77991 11.6992 2.77991 11.3981C2.87289 9.20012 3.94087 7.41308 5.3604 6.54701C6.10958 6.08652 7.13948 5.69422 8.28627 5.86956C8.77775 5.94572 9.27986 6.11398 9.71997 6.28046C10.1371 6.44074 10.6587 6.72501 11.1528 6.70995C11.4875 6.70021 11.8205 6.52576 12.1579 6.40267C13.1462 6.04579 14.115 5.63666 15.3919 5.82883C16.9266 6.06084 18.0158 6.74272 18.6888 7.79475C17.3906 8.62097 16.3643 9.86606 16.5396 11.9923C16.6955 13.9237 17.8183 15.0536 19.2211 15.7196Z"
        fill="white"
      />
    </svg>
  );
}

export default AppleIcon;
