import React from "react";

export default function AustIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <mask
        id="mask0_4662_813"
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="27"
        height="28"
      >
        <path d="M1.06177 0H27.9418V28H1.06177V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4662_813)">
        <path
          d="M27.9418 14C27.9418 21.7315 21.924 28 14.5018 28C7.07841 28 1.06177 21.7315 1.06177 14C1.06177 14.0035 14.5018 0 14.5018 0C21.924 0 27.9418 6.2685 27.9418 14Z"
          fill="#0052B4"
        />
        <path d="M14.4648 14H14.5017V13.9627L14.4648 14Z" fill="#F0F0F0" />
        <path
          d="M14.5018 7.30333V0H14.4995C7.07729 0.00116667 1.06177 6.2685 1.06177 14H8.07297V9.88633L12.0232 14H14.467L14.5029 13.9627V11.417L10.5526 7.3045L14.5018 7.30333Z"
          fill="#F0F0F0"
        />
        <path
          d="M7.8613 1.82584C5.7569 3.075 4.01377 4.89076 2.81458 7.08284V14H6.32018V5.47867H14.5018V1.827H7.86018L7.8613 1.82584Z"
          fill="#D80027"
        />
        <path
          d="M14.5018 12.278L9.72722 7.3045H8.0741L14.5018 14V12.278Z"
          fill="#D80027"
        />
        <path
          d="M9.16723 16.4348L9.9053 18.0413L11.5707 17.64L10.8248 19.243L12.1643 20.349L10.4955 20.741L10.5011 22.5213L9.16723 21.4072L7.83442 22.5213L7.83779 20.741L6.1701 20.349L7.50962 19.243L6.76371 17.64L8.43026 18.0413L9.16723 16.4348ZM21.1837 19.4787L21.5533 20.2813L22.3855 20.0807L22.0125 20.8822L22.6823 21.4352L21.8479 21.6312L21.8501 22.5213L21.1837 21.9648L20.5173 22.5213L20.5195 21.6312L19.6851 21.4352L20.3549 20.8822L19.9819 20.0807L20.8152 20.2813L21.1837 19.4787ZM17.752 10.9562L18.1216 11.76L18.9549 11.5593L18.5819 12.3608L19.2517 12.9138L18.4173 13.1098L18.4195 14L17.7531 13.4435L17.0856 14L17.0879 13.1098L16.2546 12.9138L16.9243 12.3608L16.5514 11.5593L17.3835 11.76L17.752 10.9562ZM21.1837 6.08649L21.5533 6.89032L22.3855 6.68965L22.0125 7.49115L22.6823 8.04415L21.8479 8.24015L21.8501 9.13032L21.1837 8.57382L20.5173 9.13032L20.5195 8.24015L19.6851 8.04415L20.3549 7.49115L19.9819 6.68965L20.8152 6.89032L21.1837 6.08649ZM24.1808 9.73932L24.5504 10.542L25.3826 10.3425L25.0096 11.1428L25.6794 11.6958L24.845 11.8918L24.8483 12.7832L24.1808 12.2255L23.5144 12.7832L23.5167 11.8918L22.6823 11.6958L23.352 11.1428L22.9791 10.3413L23.8123 10.542L24.1808 9.73932ZM22.0383 14L22.3283 14.9298H23.2669L22.5075 15.505L22.7976 16.4348L22.0383 15.8597L21.2789 16.4348L21.5679 15.505L20.8085 14.9298H21.7471L22.0383 14Z"
          fill="#F0F0F0"
        />
      </g>
    </svg>
  );
}
