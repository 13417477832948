import {
  Box,
  Button,
  Grid,
  Input,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomCard from "../components/ui/CustomCard";
import CardHeader from "../components/HelpCenter/CardHeader";
import videoPreview from "../assets/videoPreview.png";
import RightArrowIcon from "../components/Icons/RightArrowIcon";
import Notifications from "../components/Layout/Header/Notifications";
import SearchIcon from "../components/Icons/SearchIcon";

export default function HelpCenter() {
  return (
    <Stack direction="row" sx={{ width: "100%", height: "100%" }}>
      <Box>
        <Box>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h2_600" color={(theme) => theme.palette.color.darkText}>Help Center</Typography>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Input endAdornment={
                  <InputAdornment position="end">
                    <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                      <span>icon</span>
                      <span>icon</span>
                    </Stack>
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                className="custom_input search-input"
                />
              <Notifications />
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ width: "100%", pt: "25px " }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            pb={"12px"}
          >
            <Typography variant="h6">CRM</Typography>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "24px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
                color: (theme) => theme.palette.color.secondary,
                textTransform: "capitalize",
                display: "flex",
                gap: "16px",
              }}
            >
              Explore more <RightArrowIcon />
            </Button>
          </Stack>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                      <Stack pt={"12px"}>
                        <Typography
                          variant="paragraph"
                          sx={{
                            color: (theme) => theme.palette.color.secondary,
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </Typography>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                      <Stack pt={"12px"}>
                        <Typography
                          variant="paragraph"
                          sx={{
                            color: (theme) => theme.palette.color.secondary,
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </Typography>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                      <Stack pt={"12px"}>
                        <Typography
                          variant="paragraph"
                          sx={{
                            color: (theme) => theme.palette.color.secondary,
                          }}
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </Typography>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack pb={"12px"}>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                      <Typography
                        variant="paragraph"
                        sx={{ color: (theme) => theme.palette.color.secondary }}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </Typography>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack pb={"12px"}>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                      <Typography
                        variant="paragraph"
                        sx={{ color: (theme) => theme.palette.color.secondary }}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </Typography>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack pb={"12px"}>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                      <Typography
                        variant="paragraph"
                        sx={{ color: (theme) => theme.palette.color.secondary }}
                      >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                      </Typography>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ width: "100%", pt: "32px" }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            pb={"12px"}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  fontSize: "16px",
                }}
              >
                Policies
              </Typography>
              <Typography
                variant="paragraph"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "12px",
                }}
              >
                Learn how to get up and running with Unifunded through
                tutorials, APIs and…
              </Typography>
            </Box>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "24px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
                color: (theme) => theme.palette.color.secondary,
                textTransform: "capitalize",
                display: "flex",
                gap: "16px",
              }}
            >
              Explore more <RightArrowIcon />
            </Button>
          </Stack>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box>
                      <Stack>
                        <Box sx={{ width: "100%", padding: "4px 4px 0" }}>
                          <img
                            style={{ width: "100%" }}
                            src={videoPreview}
                            alt="img"
                          />
                        </Box>
                      </Stack>
                      <Stack p={"16px"}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            variant="paragraph"
                          >
                            Title
                          </Typography>
                          <Button
                            sx={{
                              borderRadius: "24px",
                              border: "1px solid",
                              borderColor: (theme) =>
                                theme.palette.color.strockborder,
                              color: (theme) => theme.palette.color.secondary,
                            }}
                            variant="outlined"
                          >
                            11:11
                          </Button>
                        </Stack>
                        <Box>
                          <Typography variant="paragraph">
                            Collection : Sub-collection
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box>
                      <Stack>
                        <Box sx={{ width: "100%", padding: "4px 4px 0" }}>
                          <img
                            style={{ width: "100%" }}
                            src={videoPreview}
                            alt="img"
                          />
                        </Box>
                      </Stack>
                      <Stack p={"16px"}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            variant="paragraph"
                          >
                            Title
                          </Typography>
                          <Button
                            sx={{
                              borderRadius: "24px",
                              border: "1px solid",
                              borderColor: (theme) =>
                                theme.palette.color.strockborder,
                              color: (theme) => theme.palette.color.secondary,
                            }}
                            variant="outlined"
                          >
                            11:11
                          </Button>
                        </Stack>
                        <Box>
                          <Typography variant="paragraph">
                            Collection : Sub-collection
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box>
                      <Stack>
                        <Box sx={{ width: "100%", padding: "4px 4px 0" }}>
                          <img
                            style={{ width: "100%" }}
                            src={videoPreview}
                            alt="img"
                          />
                        </Box>
                      </Stack>
                      <Stack p={"16px"}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            variant="paragraph"
                          >
                            Title
                          </Typography>
                          <Button
                            sx={{
                              borderRadius: "24px",
                              border: "1px solid",
                              borderColor: (theme) =>
                                theme.palette.color.strockborder,
                              color: (theme) => theme.palette.color.secondary,
                            }}
                            variant="outlined"
                          >
                            11:11
                          </Button>
                        </Stack>
                        <Box>
                          <Typography variant="paragraph">
                            Collection : Sub-collection
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ width: "100%", pt: "32px" }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            pb={"12px"}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  fontSize: "16px",
                }}
              >
                Integration
              </Typography>
              <Typography
                variant="paragraph"
                sx={{
                  color: (theme) => theme.palette.color.secondary,
                  fontSize: "12px",
                }}
              >
                Learn how to get up and running with Unifunded through
                tutorials, APIs and…
              </Typography>
            </Box>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "24px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.color.strockborder,
                color: (theme) => theme.palette.color.secondary,
                textTransform: "capitalize",
                display: "flex",
                gap: "16px",
              }}
            >
              Explore more <RightArrowIcon />
            </Button>
          </Stack>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box py={2} px={3}>
                      <Stack>
                        <Box>
                          <CardHeader title="Title" />
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box>
                      <Stack>
                        <Box sx={{ width: "100%", padding: "4px 4px 0" }}>
                          <img
                            style={{ width: "100%" }}
                            src={videoPreview}
                            alt="img"
                          />
                        </Box>
                      </Stack>
                      <Stack p={"16px"}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            variant="paragraph"
                          >
                            Title
                          </Typography>
                          <Button
                            sx={{
                              borderRadius: "24px",
                              border: "1px solid",
                              borderColor: (theme) =>
                                theme.palette.color.strockborder,
                              color: (theme) => theme.palette.color.secondary,
                            }}
                            variant="outlined"
                          >
                            11:11
                          </Button>
                        </Stack>
                        <Box>
                          <Typography variant="paragraph">
                            Collection : Sub-collection
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box>
                      <Stack>
                        <Box sx={{ width: "100%", padding: "4px 4px 0" }}>
                          <img
                            style={{ width: "100%" }}
                            src={videoPreview}
                            alt="img"
                          />
                        </Box>
                      </Stack>
                      <Stack p={"16px"}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            variant="paragraph"
                          >
                            Title
                          </Typography>
                          <Button
                            sx={{
                              borderRadius: "24px",
                              border: "1px solid",
                              borderColor: (theme) =>
                                theme.palette.color.strockborder,
                              color: (theme) => theme.palette.color.secondary,
                            }}
                            variant="outlined"
                          >
                            11:11
                          </Button>
                        </Stack>
                        <Box>
                          <Typography variant="paragraph">
                            Collection : Sub-collection
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <CustomCard customPadding="0">
                    <Box>
                      <Stack>
                        <Box sx={{ width: "100%", padding: "4px 4px 0" }}>
                          <img
                            style={{ width: "100%" }}
                            src={videoPreview}
                            alt="img"
                          />
                        </Box>
                      </Stack>
                      <Stack p={"16px"}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{ fontSize: "16px" }}
                            variant="paragraph"
                          >
                            Title
                          </Typography>
                          <Button
                            sx={{
                              borderRadius: "24px",
                              border: "1px solid",
                              borderColor: (theme) =>
                                theme.palette.color.strockborder,
                              color: (theme) => theme.palette.color.secondary,
                            }}
                            variant="outlined"
                          >
                            11:11
                          </Button>
                        </Stack>
                        <Box>
                          <Typography variant="paragraph">
                            Collection : Sub-collection
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </CustomCard>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
}
