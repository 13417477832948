import React from "react";

export default function SpainIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_4662_806)">
        <path
          d="M0 14C0 15.7125 0.308055 17.3529 0.870789 18.8695L14 20.0869L27.1292 18.8695C27.6919 17.3529 28 15.7125 28 14C28 12.2875 27.6919 10.6471 27.1292 9.13045L14 7.91306L0.870789 9.13045C0.308055 10.6471 0 12.2875 0 14H0Z"
          fill="#FFDA44"
        />
        <path
          d="M27.1292 9.13046C25.1511 3.7993 20.0195 0 14 0C7.98049 0 2.84889 3.7993 0.870789 9.13046H27.1292Z"
          fill="#D80027"
        />
        <path
          d="M0.870789 18.8695C2.84889 24.2007 7.98049 28 14 28C20.0195 28 25.1511 24.2007 27.1292 18.8695H0.870789Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_4662_806">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
