import { BiNavigation } from "react-icons/bi";
import { PiFlagPennantBold } from "react-icons/pi";
import { TbRepeat } from "react-icons/tb";
import PolandIcon from "../components/Icons/PolandIcon";
import AustIcon from "../components/Icons/AustIcon";
import JapanIcon from "../components/Icons/JapanIcon";
import SpainIcon from "../components/Icons/SpainIcon";
import FranceIcon from "../components/Icons/FranceIcon";
import GermanIcon from "../components/Icons/GermanIcon";
import RusIcon from "../components/Icons/RusIcon";

export const ticketItems = [
  {
    name: "Jens B.",
    message1: "from",
    account: "Account Number",
    message2: "requested payout of $1,500.15",
    time: "1h ago",
    icon: TbRepeat,
    buttons: [
      { text: "Reject", color: "secondary" },
      { text: "Review", color: "blue" },
    ],
    access: [0, 2],
  },
  {
    name: "Shae F.",
    message1: "opened ticket for",
    account: "Refund",
    tag: "#AI",
    icon: BiNavigation,
    time: "1h ago",

    buttons: [
      { text: "Close", color: "secondary" },
      { text: "Resolve", color: "blue" },
    ],
    access: [0, 1],
  },
  {
    account: "Account Number",
    message2: "flagged for",
    time: "1h ago",
    icon: PiFlagPennantBold,
    buttons: [
      { text: "Ignore", color: "secondary" },
      { text: "Review", color: "red" },
    ],
    access: [0, 2, 3],
  },
  {
    account: "Account Number",
    message2: "flagged for",
    time: "1h ago",
    icon: PiFlagPennantBold,
    buttons: [
      { text: "Ignore", color: "secondary" },
      { text: "Review", color: "red" },
    ],
    access: [0, 1, 3],
  },
  {
    account: "Account Number",
    message2: "flagged for",
    time: "1h ago",
    icon: PiFlagPennantBold,
    buttons: [
      { text: "Ignore", color: "secondary" },
      { text: "Review", color: "red" },
    ],
    access: [0, 1, 2, 3],
  },
];

export const bestPerformingCountries = [
  {
    icon: <RusIcon />,
    name: "Russia",
  },
  {
    icon: <GermanIcon />,
    name: "Germany",
  },
  {
    icon: <FranceIcon />,
    name: "France",
  },
  {
    icon: <SpainIcon />,
    name: "Spain",
  },
  {
    icon: <JapanIcon />,
    name: "Japan",
  },
  {
    icon: <AustIcon />,
    name: "Australia",
  },
  {
    icon: <PolandIcon />,
    name: "Poland",
  },
];

export const dummyWithDraws = [
  {
    name: "Rick",
  },
  {
    name: "Mathew",
  },
  {
    name: "Jason",
  },
  {
    name: "Brij",
  },
];
